import React, { useEffect, useMemo, useState, useRef } from "react";
import Select from "react-select";
import TableContainer from '../../components/Common/TableContainer';
import { ToastContainer,toast } from "react-toastify";
import Spinners from "../../components/Common/Spinner";
import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper"
import * as constants from "../../constants/layout"
//import { getHeader   } from "../../../components/VerticalLayout/Header"
import { Button, Col, Row, InputGroup, Label, Card, CardBody, FormGroup } from "reactstrap";
import "../../assets/scss/tabledesign.scss";
import "../../components/Common/common.scss";
import * as API_MASTER from '../../../src/api/masterapi';
import * as API_REPORTS from '../../api/reportsapi';
import axios from 'axios';
import moment from 'moment';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

// const logoutUser = async()=>{
//   localStorage.removeItem('userDetails');
//   setTimeout(() => navigate('/'), 700);
// };
const InwardPartyWiseReport = () => {
  document.title = "Reports | Inward Party-wise Report";
  const [isLoading, setLoading] = useState(false);
  const user = getLoggedInUser();
  const [inwardlist,setinwardlist] =useState([]);
  const [partylist,setpartylist] =useState([]);
  const [itemlist,setitemlist] =useState([]);
  const[totalqty,settotalqty] =useState(0);
  const ExcelJS = require('exceljs');
  const [excelButtonFlag, setExcelButtonFlag] = useState(false);
  const [selectedDates, setSelectedDates] = useState([
    new Date(),
    new Date()
]);
const [selectedPartyName, setselectedPartyName] = useState({
  label: "Select",
  value: 0
});
const [selectedItemName, setselectedItemName] = useState({
  label: "All",
  value: 0
});
const flatpickrRef = React.useRef(null);
  const handleDateChange = (selectedDates) => {
    setSelectedDates(selectedDates);
    if (selectedDates[0] != null && selectedDates[0] != undefined && selectedDates[1] != null && selectedDates[0] != undefined) {
      const flatpickrInstance = flatpickrRef.current.flatpickr;
      if (flatpickrInstance) {
          flatpickrInstance.close();
      }
  }
};
useEffect(() => {
  if (selectedDates[0] != null && selectedDates[0] != undefined && selectedDates[1] != null && selectedDates[0] != undefined) {
    getPartyList()
}
}, [selectedDates[1]]); 

  const exportToExcel = async () => {
    setLoading(true)
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Data')
      // Add headers with styling
      const headerRow = worksheet.addRow([
          'S.No.',
          'Item Name',
          'Item Group',
          'Quantity'
      ])
      headerRow.font = { bold: true }
      headerRow.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'c0d5e8' } // Background color
      }
      // Add data
      inwardlist.forEach((item, index) => {
          worksheet.addRow([
              index + 1,
              item.itemname,
              item.itemgroupname,
              item.qty
          ])
      })
      // Save the workbook
      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `Inward Party-wise Report.xlsx`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      setLoading(false)
      setExcelButtonFlag(false)
  }
useEffect(()=> {
  getPartyList()
  getItemList()
  getInwardList()
},[])

const getItemList = async () => {
  setLoading(true);
  const data = { user_id: Number(user.uid),
    loginfrom:10 };
 const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_URL +API_MASTER.MASTER_API.ITEM_MASTER_LIST , data,config);
      if (response && response.statusFlag === 3) {
          toast.error(response.message, { autoClose: 2000 });
          localStorage.removeItem("authUser");
          window.location.reload();
          return;
      }
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();
          if (refresh) {
              if (Number(refresh) === constants.invalidRefreshToken) {
                  logoutUser();
              } else {
                  await getItemList();
              }
          }
      }
      if (response?.data?.body?.itemlist) {
        response?.data?.body?.itemlist.unshift({
          itemname: "All",
          prid: 0
        })
        setitemlist(response?.data?.body?.itemlist);
      } else {
          toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
      setLoading(false);
  } catch (error) {
      setLoading(false);
      console.error("Party List API Error:", error);
  }
}

const getPartyList = async () => {
  setLoading(true);
  const data = { user_id: Number(user.uid),
    loginfrom:10, fromdate:moment(selectedDates[0]).format("YYYY-MM-DD"),todate:moment(selectedDates[1]).format("YYYY-MM-DD"),type: 2 };
 const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_URL +API_MASTER.MASTER_API.PARTY_MASTER_LIST , data,config);
      if (response && response.statusFlag === 3) {
          toast.error(response.message, { autoClose: 2000 });
          localStorage.removeItem("authUser");
          window.location.reload();
          return;
      }
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();
          if (refresh) {
              if (Number(refresh) === constants.invalidRefreshToken) {
                  logoutUser();
              } else {
                  await getPartyList();
              }
          }
      }
      if (response?.data?.body?.partylist) {
        response?.data?.body?.partylist.unshift({
          partyname: "Select",
          ptid: 0
        })
        setpartylist(response?.data?.body?.partylist);
      } else {
          toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
      setLoading(false);
  } catch (error) {
      setLoading(false);
      console.error("Party List API Error:", error);
  }
}
const getInwardList = async () => {
  // if (Number(selectedPartyName.value) != 0 || Number(selectedItemName.value) != 0)
  //   {
  setLoading(true);
  const data = { user_id: Number(user.uid), prid:Number(selectedItemName.value),ptid:Number(selectedPartyName.value),
    fromdate:moment(selectedDates[0]).format("YYYY-MM-DD"),todate:moment(selectedDates[1]).format("YYYY-MM-DD"),
    loginfrom:10};
 const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_URL +API_REPORTS.REPORTS_API.REPORTS_INWARD_PARTYWISE , data,config);
      if (response && response.statusFlag === 3) {
          toast.error(response.message, { autoClose: 2000 });
          localStorage.removeItem("authUser");
          window.location.reload();
          return;
      }
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();
          if (refresh) {
              if (Number(refresh) === constants.invalidRefreshToken) {
                  logoutUser();
              } else {
                  await getInwardList();
              }
          }
      }
      if (response?.data?.body?.inwardlist) {
        setinwardlist(response?.data?.body?.inwardlist);
        settotalqty(response?.data?.body?.totalqty);
      } else {
          toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
      setLoading(false);
  } catch (error) {
      setLoading(false);
      console.error("Inward party-wise report API Error:", error);
  }
// }
// else
//   {
//     toast.error("Choose Party or Item in Filter", { position: toast.POSITION.TOP_RIGHT });
//   }
}
  const columns = useMemo(
    () => [
      {
        header: 'S.No.',
        width: '8%',
        enableColumnFilter: false,
        className: 'pl-25',
        cell: ({ row, table }) =>
          <div className="text-center">{
            (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
          }
          </div> 
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Item Name ▼
          </span>
        ),
        accessorKey: 'itemname',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Item Group ▼
          </span>
        ),
        accessorKey: 'itemgroupname',
        enableColumnFilter: false,
        enableSorting: true,
      },{
        header: (
          <span style={{ cursor: 'pointer' }}>
            Quantity ▼
          </span>
        ),
        accessorKey: 'Quantity',
        enableColumnFilter: false,
        enableSorting: true, 
        cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-end">
                  {parseFloat(cellProps?.row?.original?.qty).toFixed(cellProps?.row?.original?.noofdecimal)}
            </div>
          );
        }
      },{
        header: (
          <span style={{ cursor: 'pointer' }}>
           
          </span>
        ),
        accessorKey: 'unit',
        enableColumnFilter: false,
        enableSorting: true, 
        cell: (cellProps) => {
          return (
            <div className="text-left">
                  {cellProps?.row?.original?.unit}
            </div>
          );
        }
      }
    ],
    []
  );  
  function handleSelectPartyName(selectedPartyName) {
    setselectedPartyName(selectedPartyName); }
  useEffect(() => {
    document.getElementById('headerTitle').innerHTML = 'Inward Party-wise Report';
  }, []);

  function handleSelectItemName(selectedItemName) {
    setselectedItemName(selectedItemName); }
  useEffect(()=> {
    document.getElementById('headerTitle').innerHTML = 'Inward Party-wise Report';
  },[])
  return (
    <React.Fragment>
      <div className="page-content" style={{ height: '100%' }}>
        <div className="container-fluid">
          {/* <Breadcrumbs title="Masters" breadcrumbItem="Exam Board" /> */}
          <Row>
            {isLoading ?
              <Spinners setLoading={setLoading} />
              :
              <Col xs="12">
                <Card>
                  {/* <CardBody className="cust-table-ht"> */}
                  <CardBody >
                  <Row className="g-3">
                      <Col lg={4} >                        
                      </Col>
                        <Row>                             
                        <Col lg={3}>
                        <div className="mb-3">
                            <FormGroup className="mb-4">
                                <Label>Period</Label>
                                <InputGroup>
                                  <Flatpickr data-enable-time
                                                                                                  ref={flatpickrRef}
                                      value={selectedDates}
                                      className="form-control d-block"
                                      options={{
                                          mode: "range",
                                          dateFormat: "d-m-Y",
                                          onChange: handleDateChange // Attach onChange handler to update state
                                      }}
                                  /><button className="btn btn-light" type="button" id="calendar-addon">
                                  <i className="bx bx-calendar-event"></i>
                              </button>
                              </InputGroup>
                                {/* <div>
                                    Selected Dates: {selectedDates && selectedDates.length > 0 ? selectedDates.map(date => date.toLocaleDateString()).join(' - ') : 'Select a date range'}
                                </div> */}
                            </FormGroup>
                        </div>
                    </Col>
                          <Col lg={3}>
                            <div className="mb-3">
                              <Label>Party Name</Label>
                              <Select
                                value={selectedPartyName}
                                onChange={(selectedOption) => {
                                  handleSelectPartyName(selectedOption);
                                }} 
                                options={partylist.map((option) => ({
                                  label: option.partyname,
                                  value: option.ptid,
                                }))}
                                className="select2-selection"
                                />
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="mb-3">
                              <Label>Item Name</Label>
                              <Select
                                value={selectedItemName}
                                onChange={(selectedOption) => {
                                  handleSelectItemName(selectedOption);
                                }} 
                                options={itemlist.map((option) => ({
                                  label: option.itemname,
                                  value: option.prid,
                                }))}
                                className="select2-selection"
                                />
                            </div>
                          </Col>
                          <Col lg={1} >
                              <div className="d-flex flex-wrap gap-2 justify-content-start">
                                <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-soft-info"
                                  onClick={() => getInwardList()}
                                 // onClick={() => setFilterData({selectedPartyName: selectedItemGroup ? selectedItemGroup.value : 0})}
                                 >
                                  Apply
                                </Button>
                              </div>
                            </Col>
                            <Col lg={2} >
                                <div className='d-flex flex-wrap gap-2 justify-content-start'>
                                <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-soft-info"
                                   onClick={() => exportToExcel()} disabled={excelButtonFlag ? true : false}>
                                   <i className="fa fa-download" /> &nbsp; Export
                                </Button>
                                </div>
                            </Col>
                            {/* <Col lg={3}>
                                <div className="mini-stats-wid btn-soft-info btn-secondary">
                                <CardBody>
                                    <div className="d-flex">
                                    <div className="flex-grow-1">
                                        <p className="text-muted fw-medium">
                                        Total Items
                                        </p>
                                        <h4 className="mb-0">{totalqty}</h4>
                                    </div>
                                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                        <span className="avatar-title rounded-circle bg-primary">
                                        <i className={"bx bx-archive-in font-size-24"}></i>
                                        </span>
                                    </div>
                                    </div>
                                </CardBody>
                                </div>
                            </Col> */}
                        </Row>
                    </Row>
                    <TableContainer
                        columns={columns}
                        data={inwardlist || []}
                      //  isGlobalFilter={true}
                        // isPagination={true}
                       // isAddButton={canCreate}
                        buttonName="Add"
                       // SearchPlaceholder="Search..."
                        // pagination="pagination"
                        //handleUserClick={handleAddClick}
                       // isCustomPageSize={true}
                        buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"
                        tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                        theadClass="table-light"
                        // paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    />
                  </CardBody>
                </Card>
              </Col>
            }
          </Row>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </React.Fragment>
  );
}

export default InwardPartyWiseReport;