import React, { useEffect, useMemo, useState, useRef } from "react";
import * as Yup from "yup";
import Select from "react-select";
import TableContainer from '../../../components/Common/TableContainer';
import { ToastContainer,toast } from "react-toastify";
import Spinners from "../../../components/Common/Spinner";
import { useFormik } from "formik";
import DeleteModal from "../../../components/Common/DeleteModal";
import { getLoggedInUser, getRefreshToken } from "../../../helpers/fakebackend_helper"
import * as constants from "../../../constants/layout"
import { Button, Col, Row, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Form, Input, FormFeedback, Label, Card, CardBody, FormGroup, Badge } from "reactstrap";
import "../../../assets/scss/tabledesign.scss";
import "../../../assets/scss/common.scss";
import * as API_MASTER from '../../../../src/api/masterapi';
import axios from 'axios';
const UnitMaster = () => {
  document.title = "Masters | Unit";
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const inputRef = useRef(null);
  const [unitlist, setUnitList] = useState(null);
  const [decimallist, setdecimallist] = useState(null);
  const [unit, setUnit] = useState(null);
  const [submitbtn, setSubmitbtn] = useState('');
  const [deleteTrigger, setDeleteTrigger] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [canCreate, setCanCreate] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const user = getLoggedInUser();
  const [selecteddecimal, setselecteddecimal] = useState(null);
  const logoutUser = async()=>{
    localStorage.removeItem('userDetails');
    setTimeout(() => navigate('/'), 700);
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      utid:(unit && unit.utid),
      unitname: (unit && unit.unitname) || '',
      symbol: (unit && unit.symbol) || '',
      selecteddecimal: (unit && unit.selecteddecimal) || null
    },
    validationSchema: Yup.object({
    unitname: Yup.string().required("Please enter unit"),
      symbol: Yup.string().required("Please enter symbol"),
      selecteddecimal: Yup.object().required("Please select no.of decimals")
    }),
    onSubmit: (values) => {
      setLoading(true);
      const addUnit = {
        user_id: Number(user.uid),
        utid:Number(values.utid),
        unitname: values.unitname,
        symbol:values.symbol,  
        decimals:Number(selecteddecimal.value),    
        process_type: isEdit ? 'update' : 'save',
      };
       disableButton();
       udfnSaveUnit(addUnit);      
    },
  });
  const udfnClear = ()=>{
    enableButton()
    validation.resetForm();
    setIsEdit(false);
    setUnit();
    setselecteddecimal(null);
    inputRef.current.focus();
  }
  const disableButton = () => {
    setButtonDisabled(true);
  };
  const enableButton = () => {
    setButtonDisabled(false);
  };
  const udfnSaveUnit = async (values) => { 
		setLoading(true);
		const user = getLoggedInUser();
    var apiUrl='';
		var uploadData = {};
		if (isEdit === true) {
			uploadData = {
				user_id: values.user_id,
				utid:  values.utid,
				ut_name: values.unitname.trim(),
				ut_symbol: values.symbol.trim(),
				ut_decimal: values.decimals,
        loginfrom:10
			};
			apiUrl =  API_MASTER.MASTER_API.UNIT_MASTER_UPDATE;
		} else {
			uploadData = {
				user_id:values.user_id,
				utid:0,
				ut_name: values.unitname.trim(),
				ut_symbol: values.symbol.trim(),
				ut_decimal: values.decimals,
        loginfrom:10
			};
			apiUrl =  API_MASTER.MASTER_API.UNIT_MASTER_SAVE;
		}
		const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
    const response = await axios.post(process.env.REACT_APP_BASE_URL +apiUrl , uploadData,config);
    try {
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();
          if (refresh) {
              if (Number(refresh) === constants.invalidRefreshToken) {
                  logoutUser();
              } else {
                  await udfnSaveUnit(values);
              }
          }
      }    
      if (response && response?.data?.body?.status == '200' || response && response?.data?.body?.status == 200) {
        if (isEdit === true) {
          setModal(false);   
        }
        else{         
          if (submitbtn == 'savecontinue') {
            setModal(true);   
          }
          else{setModal(false);}
        }
        enableButton();
        udfnClear();
        toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
        getUnitList();
      } else {
        enableButton();
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
		} catch (error) {
      enableButton();
			setLoading(false);
      toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
		}
	};  
  const onClickDelete = (values) => {
    setUnit(values);
    setDeleteModal(true);
  };
  const udfnDeleteUnit = async (values) => { 
		setLoading(true);
		const user = getLoggedInUser();
    var apiUrl =  API_MASTER.MASTER_API.UNIT_MASTER_DELETE;
		var uploadData = {
      user_id:values.user_id,
      utid:values.utid,
      loginfrom:10
		}
		const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
    const response = await axios.post(process.env.REACT_APP_BASE_URL +apiUrl , uploadData,config);
    try {
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();
          if (refresh) {
              if (Number(refresh) === constants.invalidRefreshToken) {
                  logoutUser();
              } else {
                  await udfnDeleteUnit(values);
              }
          }
      }    
      if (response && response?.data?.body?.status == '200' || response && response?.data?.body?.status == 200) {       
        setLoading(false);
        toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
        getUnitList();
      } else {
        setLoading(false);
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
		} catch (error) {
			setLoading(false);
      toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
		}
	};
useEffect(()=> {
  setCanCreate(true)
},[])
const columns = useMemo(
    () => [
      {
        header: 'Actions',
        id:'Action',
        width: '8%',
        cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <div
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      const unitdata = cellProps.row.original;
                      onEditClick(unitdata);
                    }}
                  >
                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </div>
                  <div  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const unitdata = cellProps.row.original; onClickDelete(unitdata);
                  }}
                  >
                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </div>
            </div>
          );
        }
      },
      {
        header: 'S.No.',
        width: '8%',
        enableColumnFilter: false,
        className: 'pl-25 ',
        cell: ({ row, table }) =>
          <div className="text-center">{
            (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
          }
          </div>           
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Unit Name ▼
          </span>
        ),
        accessorKey: 'unitname',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Symbol ▼
          </span>
        ),
        accessorKey: 'symbol',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            No.of decimals ▼
          </span>
        ),
        accessorKey: 'decimals',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <div className="text-center">
                  {cellProps?.row?.original?.decimals}
            </div>
          );
        }
      },
      {
        header: (
          <span style={{ cursor: 'pointer',textAlign:'left' }}>
            No.of Items ▼
          </span>
        ),
        accessorKey: 'totalitems',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <div className="text-center">
                  {cellProps?.row?.original?.totalitems}
            </div>
          );
        }
      },
      // {
      //   header: (
      //     <span style={{ cursor: 'pointer' }}>
      //       Created By ▼
      //     </span>
      //   ),
      //   accessorKey: 'created',
      //   enableColumnFilter: false,
      //   enableSorting: true,
      //   cell: (cellProps) => {
      //     return (
      //       <div className="created">
      //             {cellProps?.row?.original?.created}<br/>
      //             {cellProps?.row?.original?.createdon}
      //       </div>
      //     );
      //   }
      // },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
          Last Updated
        </span>
        ),
        accessorKey: 'updated',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const updatedby = cellProps?.row?.original?.updated;
          return (
            <div className="created">
            {updatedby === "" || updatedby === "-" ? (
              <div>{cellProps?.row?.original?.created}<br/>
                 {cellProps?.row?.original?.createdon}</div>
            ) : (
                 <div>{cellProps?.row?.original?.updated}<br/>
                 {cellProps?.row?.original?.updatedon}</div>
               )}
               </div>
          );
        }
      }
    ],
    []
  );
  const handleAddClick = () => {
    enableButton()
    validation.resetForm();
    setIsEdit(false);
    setUnit();
    setselecteddecimal();
    toggle();
  };
  const toggle = () => {
    setModal(prev => !prev);
  };
  const handleSeletedDecimals = (selectedOption) => {
    setselecteddecimal(selectedOption);
  };
  useEffect(() => {
    if (modal && inputRef.current && inputFocus) {
      inputRef.current.focus();
      setInputFocus(false);
    }
  }, [modal, inputFocus]);

  useEffect(() => {
    if (modal) {
      setInputFocus(true);
    }
  }, [modal]);
  const onEditClick = (unitdata) => {
    setLoading(true);
    enableButton()
    validation.resetForm();
  //  setUnit(unitdata);
    toggle();
    setIsEdit(true);
    validation.setValues({ 
      unitname: unitdata.unitname,
      utid:unitdata.utid,
      symbol:unitdata.symbol,
      selecteddecimal: {
        label: unitdata.decimals,
        value: unitdata.mstid
      }
    });
    setselecteddecimal({
      label: unitdata.decimals,
      value: unitdata.mstid
    })
    setLoading(false);
  }
  const handleDeleteOrder = () => {
    setDeleteTrigger(true);
    setLoading(true); 
    const deleteUnit = {
      user_id: Number(user.uid),
      utid:Number(unit.utid),
      loginfrom:10
    };
    udfnDeleteUnit(deleteUnit);
    setDeleteModal(false);
  };
  const getUnitList = async () => {
     setLoading(true);
    const data = { user_id: Number(user.uid),loginfrom:10 };
   const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
    try {
        const response = await axios.post(process.env.REACT_APP_BASE_URL +API_MASTER.MASTER_API.UNIT_MASTER_LIST , data,config);
        if (response && response.statusFlag === 3) {
            toast.error(response.message, { autoClose: 2000 });
            localStorage.removeItem("authUser");
            window.location.reload();
            return;
        }
        if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
            const refresh = await getRefreshToken();
            if (refresh) {
                if (Number(refresh) === constants.invalidRefreshToken) {
                    logoutUser();
                } else {
                    await getUnitList();
                }
            }
        }
        if (response?.data?.body?.unitlist) {
          setUnitList(response.data.body.unitlist);
          setLoading(false);
        } else {
            toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            setLoading(false);
        }
    } catch (error) {
        // Handle error
        console.error("Unit List API Error:", error);
    }
  }
  const getDecimalList = async () => {
    setLoading(true);
   const data = { user_id: Number(user.uid),
    loginfrom:10 };
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
   try {
       const response = await axios.post(process.env.REACT_APP_BASE_URL +API_MASTER.MASTER_API.DECIMAL_LIST , data,config);
       if (response && response.statusFlag === 3) {
           toast.error(response.message, { autoClose: 2000 });
           localStorage.removeItem("authUser");
           window.location.reload();
           return;
       }
       if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
           const refresh = await getRefreshToken();
           if (refresh) {
               if (Number(refresh) === constants.invalidRefreshToken) {
                   logoutUser();
               } else {
                   await getDecimalList();
               }
           }
       }
       if (response?.data?.body?.decimalresult) {
        setdecimallist(response.data.body.decimalresult);
         setLoading(false);
       } else {
           toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
           setLoading(false);
       }
   } catch (error) {
       // Handle error
       console.error("Decimal List API Error:", error);
   }
 }
  useEffect(() => {
    document.getElementById('headerTitle').innerHTML = 'Unit';
  });
  useEffect(() => {
    getUnitList()
    getDecimalList()
  },[]);
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content" style={{ height: '100%' }}>
        <div className="container-fluid">
          {/* <Breadcrumbs title="Masters" breadcrumbItem="Exam Board" /> */}
          <Row>
            {isLoading ?
              <Spinners setLoading={setLoading} />
              :
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={unitlist || []}
                      isGlobalFilter={true}
                      isPagination={true}
                      isAddButton={canCreate}
                      buttonName="Add"
                      SearchPlaceholder="Search..."
                      pagination="pagination"
                      handleUserClick={handleAddClick}
                     // isCustomPageSize={true}
                      buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"
                      tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                      theadClass="table-light"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    />
                  </CardBody>
                </Card>
              </Col>
            }
          </Row>
          <Modal isOpen={modal} toggle={toggle} backdrop="static">
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Unit" : "Add Unit"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                  <div className="mb-3">
                      <Label>Unit Name<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        name="unitname"
                        type="text"
                        placeholder="Unit Name"
                        validate={{ required: { value: true } }}
                        maxLength={constants.textboxLength.unit_name}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.unitname || ""}
                        autoComplete="off"
                        invalid={validation.touched.unitname && validation.errors.unitname}
                        innerRef={inputRef}/>
                      {validation.touched.unitname && validation.errors.unitname ? (
                        <FormFeedback type="invalid">{validation.errors.unitname}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Symbol<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        name="symbol"
                        type="text"
                        placeholder="Symbol"
                        validate={{ required: { value: true } }}
                        maxLength={constants.textboxLength.symbol}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                            autoComplete="off"
                        value={validation.values.symbol || ""}
                        invalid={validation.touched.symbol && validation.errors.symbol}/>
                      {validation.touched.symbol && validation.errors.symbol ? (
                        <FormFeedback type="invalid">{validation.errors.symbol}</FormFeedback>
                      ) : null}
                    </div>
                  <div className="mb-3">
                      <Label>No.of decimals<span style={{ color: 'red' }}>*</span></Label>
                      <Select
                        value={selecteddecimal}
                        onChange={(selectedOption) => {
                          handleSeletedDecimals(selectedOption);
                          validation.setFieldValue("selecteddecimal", selectedOption)
                        }}
                        options={decimallist?.map((decimal) => ({
                          label: decimal.mst_displaytext,
                          value: decimal.mstid,
                        }))}
                        validate={{
                          required: { value: true },
                        }}
                        className={`select2-selection ${validation.touched.selecteddecimal && validation.errors.selecteddecimal ? 'is-invalid' : ''}`}
                        invalid={
                          validation.touched.selecteddecimal && validation.errors.selecteddecimal ? true : false
                        }
                      />
                      {validation.touched.selecteddecimal && validation.errors.selecteddecimal ? (
                        <FormFeedback type="invalid">{validation.errors.selecteddecimal}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        color="success"
                        type="submit"
                        className="save-user"
                        onClick={() => setSubmitbtn('savebtn')}
                        disabled={isButtonDisabled}

                      >
                        {!!isEdit ? "Update" : "Save"}
                      </Button>
                      {!isEdit && (
                        <Button
                          color="primary"
                          type="submit"
                          className="save-and-continue-user"
                          onClick={() => setSubmitbtn('savecontinue')}
                          style={{ marginLeft: '10px' }}
                          disabled={isButtonDisabled}>
                          Save & Continue
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </React.Fragment>
  );
}

export default UnitMaster;