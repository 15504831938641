import React from "react";
import { Navigate } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
// import Register from "../pages/Authentication/Register";
// import ForgetPwd from "../pages/Authentication/ForgetPassword";

import BoardList from "pages/Masters/Board/list-boards";
import ItemGroup from "pages/Masters/ItemGroup/itemgroup";
import User from "pages/Masters/User/user";
import ItemMaster from "pages/Masters/ItemMaster/item";
import UnitMaster from "pages/Masters/Unit/unit";
import PartyMaster from "pages/Masters/Party/party";
import PriceChange from "pages/Masters/PriceChange/pricechange";
import PriceList from "../pages/Reports/pricelist";
import OutwardPartyWiseReport from "../pages/Reports/outwardpartywise";
import InwardPartyWiseReport from "../pages/Reports/inwardpartywise";
import OutwardDateWiseReport from "../pages/Reports/outwarddatewise";
import InwardDateWiseReport from "../pages/Reports/inwarddatewise";
const authProtectedRoutes = [
  // //profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/login" />,
  },
  {
    path: "/list-boards",
    exact: true,
    component: <BoardList />,
  }, 
  {
    path: "/itemgroup",
    exact: true,
    component: <ItemGroup/>,
  }, 
  {
    path: "/item",
    exact: true,
    component: <ItemMaster/>,
  },
  {
    path: "/unit",
    exact: true,
    component: <UnitMaster/>,
  },
  {
    path: "/party",
    exact: true,
    component: <PartyMaster/>,
  },
  {
    path: "/price-change",
    exact: true,
    component: <PriceChange/>,
  },
  {
    path: "/user",
    exact: true,
    component: <User/>
  },
  {
    path: "/price-list",
    exact: true,
    component: <PriceList/>
  }, {
    path: "/outward-party-wise-report",
    exact: true,
    component: <OutwardPartyWiseReport/>
  },
  {
    path: "/inward-party-wise-report",
    exact: true,
    component: <InwardPartyWiseReport/>
  },
  {
    path: "/outward-date-wise-report",
    exact: true,
    component: <OutwardDateWiseReport/>
  },
  {
    path: "/inward-date-wise-report",
    exact: true,
    component: <InwardDateWiseReport/>
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  // { path: "/forgot-password", component: <ForgetPwd /> },
  // { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
