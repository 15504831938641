import React, { useEffect, useMemo, useState, useRef } from "react";
import * as Yup from "yup";
import Select from "react-select";
import TableContainer from '../../../components/Common/TableContainer';
import { ToastContainer ,toast} from "react-toastify";
import Spinners from "../../../components/Common/Spinner";
import { useFormik } from "formik";
import DeleteModal from "../../../components/Common/DeleteModal";
//import { getExamBoards, addItemGroup, resetStore, deleteExamBoard } from "../../../store/actions";
import { getLoggedInUser,getRefreshToken } from "../../../helpers/fakebackend_helper"
import * as constants from "../../../constants/layout"
//import { getHeader   } from "../../../components/VerticalLayout/Header"
import { Button, Col, Row, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Form, Input, FormFeedback, Label, Card, CardBody, FormGroup, Badge } from "reactstrap";
import "../../../assets/scss/tabledesign.scss";
import "../../../assets/scss/common.scss";
import * as API_MASTER from '../../../api/masterapi'
import axios from 'axios';
const ItemMaster = () => {
  document.title = "Masters | Item";
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const inputRef = useRef(null);
  const [item, setItem] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [canCreate, setCanCreate] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const user = getLoggedInUser();
  const [selectedgroup, setselectedgroup] = useState(null);
  const [selectedunit, setselectedunit] = useState(null);
  const [itemgrouplist,setItemgroupList] =useState([]);
  const [unitlist,setunitlist] =useState([]);
  const [itemlist,setitemlist] =useState([]);
  const [submitbtn, setSubmitbtn] = useState('');
  const [status, setstatus] = useState(false);
  const [status1, setStatus1] = useState(false);
  const [deleteTrigger, setDeleteTrigger] = useState(false);  
  const logoutUser = async()=>{
    localStorage.removeItem('userDetails');
    setTimeout(() => navigate('/'), 700);
  };

const toggleStatus = () => {
  setStatus1(!status1)
}

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      itemname: (item && item.itemname) || '',
      selectedunit: (item && item.selectedunit) || null,
      selectedgroup: (item && item.selectedgroup) || null,
      prid: (item && item.prid) || 0,
      status : status,
    },
    validationSchema: Yup.object({
      itemname: Yup.string().required("Please enter item name"),
      selectedunit: Yup.object().required("Please select unit"),
      selectedgroup: Yup.object().required("Please select item group")
    }),
    onSubmit: (values) => {
      setLoading(true);
      const addItem = {
        user_id: Number(user.uid),
        prid:Number(values.prid),
        itemname: values.itemname,
        utid:Number(selectedunit.value),
        prgid:Number(selectedgroup.value),
        statusid:Number(status),
        process_type: isEdit ? 'update' : 'save'
      };
       disableButton();
       udfnSaveItem(addItem);
    },
  });  
  const udfnClear = ()=>{
    console.log('------')
    setItem('');
    enableButton()
    validation.resetForm();
    setIsEdit(false);
    setselectedgroup(null);
    setselectedunit(null);
    inputRef.current.focus();
  }
  const disableButton = () => {
    setButtonDisabled(true);
  };
  const enableButton = () => {
    setButtonDisabled(false);
  };
  const udfnSaveItem = async (values) => { 
		setLoading(true);
		const user = getLoggedInUser();
    var apiUrl='';
		var uploadData = {};
    var varstatus ="1";
    if(values.statusid =="0"){varstatus=2;}
		if (isEdit === true) {
			uploadData = {
				user_id: values.user_id,
				prid: Number(values.prid),
				pr_name: values.itemname.trim(),
				pr_utid: Number(values.utid),
				pr_prgid: Number(values.prgid),
        pr_stsid:Number(varstatus),
        loginfrom:10
			};
			apiUrl =  API_MASTER.MASTER_API.ITEM_MASTER_UPDATE;
		} else {
			uploadData = {
        user_id: values.user_id,
				prid:  0,
				pr_name: values.itemname.trim(),
				pr_utid: Number(values.utid),
				pr_prgid: Number(values.prgid),
        loginfrom:10
			};
			apiUrl =  API_MASTER.MASTER_API.ITEM_MASTER_SAVE;
		}
		const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
    const response = await axios.post(process.env.REACT_APP_BASE_URL +apiUrl , uploadData,config);
    try {
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();
          if (refresh) {
              if (Number(refresh) === constants.invalidRefreshToken) {
                  logoutUser();
              } else {
                  await udfnSaveItem(values);
              }
          }
      }    
      if (response && response?.data?.body?.status == '200' || response && response?.data?.body?.status == 200) {
        if (isEdit === true) {
          setModal(false);   
        }
        else{          
          if (submitbtn !== 'savecontinue') {
            setModal(false);        
          }
        }
        enableButton();
        udfnClear();
        toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
        getItemList();
      } else {
        enableButton();
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
		} catch (error) {
      enableButton();
			setLoading(false);
      toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
		}
	};
  const udfnDeleteItem = async (values) => { 
		setLoading(true);
		const user = getLoggedInUser();
    var apiUrl =  API_MASTER.MASTER_API.ITEM_MASTER_DELETE;
		var uploadData = {
      user_id:values.user_id,
      prid:values.prid,
      loginfrom:10
		}
		const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
    const response = await axios.post(process.env.REACT_APP_BASE_URL +apiUrl , uploadData,config);
    try {
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();
          if (refresh) {
              if (Number(refresh) === constants.invalidRefreshToken) {
                  logoutUser();
              } else {
                  await udfnDeleteItem(values);
              }
          }
      }    
      if (response && response?.data?.body?.status == '200' || response && response?.data?.body?.status == 200) {       
        setLoading(false);
        toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
        getItemList();
      } else {
        setLoading(false);
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
		} catch (error) {
			setLoading(false);
      toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
		}
	};  
useEffect(()=> {
  setCanCreate(true)
},[])
const columns = useMemo(
    () => [
      {
        header: 'Actions',
        id:'Action',
        width: '8%',
        cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <div
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      const itemdata = cellProps.row.original;
                      onEditClick(itemdata);
                    }}
                  >
                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </div>
                  <div  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const itemgroupdata = cellProps.row.original; onClickDelete(itemgroupdata);
                  }}
                  >
                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </div>
            </div>
          );
        }
      },
      {
        header: 'S.No.',
        width: '8%',
        enableColumnFilter: false,
        className: 'pl-25',
        cell: ({ row, table }) =>
          <div className="text-center">{
            (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
          }
          </div>  

      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Item Group Name ▼
          </span>
        ),
        accessorKey: 'itemgroupname',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Item Name ▼
          </span>
        ),
        accessorKey: 'itemname',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Unit▼
          </span>
        ),
        accessorKey: 'unit',
        enableColumnFilter: false,
        enableSorting: true,
         cell: (cellProps) => {
          return (
            <div className="text-center">
                  {cellProps?.row?.original?.unit}
            </div>
          );
        }
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Status
          </span>
        ),
        accessorKey: 'statusid',
        cell: (cellProps) => {
            const u_stsid = cellProps.row.original.statusid;
            return (
                <div className="text-center">
                    {u_stsid === "1" ? (
                        <i className="mdi mdi-check-circle text-success me-2" style={{ fontSize: '25px' }} />
                    ) : (
                        <i className="dripicons dripicons-wrong text-danger me-2" style={{ fontSize: '20px' }} />
                    )}
                </div>
            );
        },
        enableColumnFilter: false,
        enableSorting: true,
      },
      // {
      //   header: (
      //     <span style={{ cursor: 'pointer' }}>
      //       Created By ▼
      //     </span>
      //   ),
      //   id:'created Action',
      //   accessorKey: 'created',
      //   enableColumnFilter: false,
      //   enableSorting: true,
      //   cell: (cellProps) => {
      //     return (
      //       <div className="created">
      //             {cellProps?.row?.original?.created}<br/>
      //             {cellProps?.row?.original?.createdon}
      //       </div>
      //     );
      //   }
      // },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
          Last Updated
        </span>
        ),
        
        id:'update Action',
        accessorKey: 'updated',
        // enableColumnFilter: false,
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const updatedby = cellProps?.row?.original?.updated;
          return (
            <div className="created">
            {updatedby === "" || updatedby === "-" ? (
              <div>{cellProps?.row?.original?.created}<br/>
                 {cellProps?.row?.original?.createdon}</div>
            ) : (
                 <div>{cellProps?.row?.original?.updated}<br/>
                 {cellProps?.row?.original?.updatedon}</div>
               )}
               </div>
          );
        }
      }
    ],
    []
  );
  const onClickDelete = (itemData) => {
    setItem(itemData);
    setDeleteModal(true);
  };
  const handleAddClick = () => {
    console.log('----------')
    enableButton()
    validation.resetForm();
    setIsEdit(false);
    setItem('');    
    setselectedgroup();
    setselectedunit();
    toggle();
  };
  const toggle = () => {
    setModal(prev => !prev);
  };
  const handleSelectGroup = (selectedOption) => {
    setselectedgroup(selectedOption);
  };
  const handleSelectunit = (selectedOption) => {
    setselectedunit(selectedOption);
  };
  useEffect(() => {
    if (modal && inputRef.current && inputFocus) {
      inputRef.current.focus();
      setInputFocus(false);
    }
  }, [modal, inputFocus]);

  useEffect(() => {
    if (modal) {
      setInputFocus(true);
    }
  }, [modal]);

 
  const onEditClick = (itemdata) => {
    console.log('======')
    setLoading(true);
    enableButton()
    validation.resetForm();
   // setItem(itemdata);
    toggle();
    setIsEdit(true);
    validation.setValues({ 
      prid: itemdata.prid,
      itemname:itemdata.itemname,
      selectedunit: {
        label: itemdata.unit,
        value: itemdata.pr_utid
      },
      selectedgroup: {
        label: itemdata.itemgroupname,
        value: itemdata.pr_prgid
      }
    });
    setselectedgroup({
      label: itemdata.itemgroupname,
        value: itemdata.pr_prgid
    })
    setselectedunit({
      label: itemdata.unit,
      value: itemdata.pr_utid
    })
    if (itemdata.statusid == "1"){
      setStatus1(true)
    }
  else{
    setStatus1(false)
  } 

  }
  const handleDeleteOrder = () => {
    setDeleteTrigger(true);
    setLoading(true);
    const deleteItem = {
      user_id: Number(user.uid),
      prid:Number(item.prid)
    };
    udfnDeleteItem(deleteItem);
    setDeleteModal(false);
  };  
const getItemGroupList = async () => {
  setLoading(true);
  const data = { user_id: Number(user.uid),
    loginfrom:10 };
 const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_URL +API_MASTER.MASTER_API.ITEMGROUP_MASTER_LIST , data,config);
      if (response && response.statusFlag === 3) {
          toast.error(response.message, { autoClose: 2000 });
          localStorage.removeItem("authUser");
          window.location.reload();
          return;
      }
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();
          if (refresh) {
              if (Number(refresh) === constants.invalidRefreshToken) {
                  logoutUser();
              } else {
                  await getItemGroupList();
              }
          }
      }
      if (response?.data?.body?.itemgrouplist) {
      //  response?.data?.body?.itemgrouplist.unshift({
      //     itemgroupname: "All",
      //     prgid: 0
      //   })
        setItemgroupList(response?.data?.body?.itemgrouplist);
      } else {
          toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
      setLoading(false);
  } catch (error) {
      // Handle error
      setLoading(false);
      console.error("Item Group List API Error:", error);
  }
}
const getUnitList = async () => {
  setLoading(true);
  const data = { user_id: Number(user.uid),
    loginfrom:10 };
 const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_URL +API_MASTER.MASTER_API.UNIT_MASTER_LIST , data,config);
      if (response && response.statusFlag === 3) {
          toast.error(response.message, { autoClose: 2000 });
          localStorage.removeItem("authUser");
          window.location.reload();
          return;
      }
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();
          if (refresh) {
              if (Number(refresh) === constants.invalidRefreshToken) {
                  logoutUser();
              } else {
                  await getUnitList();
              }
          }
      }
      if (response?.data?.body?.unitlist) {
        setunitlist(response?.data?.body?.unitlist);
      } else {
          toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
      setLoading(false);
  } catch (error) {
      setLoading(false);
      console.error("Unit List API Error:", error);
  }
}
const getItemList = async () => {
  setLoading(true);
  const data = { user_id: Number(user.uid),
    loginfrom:10 };
 const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_URL +API_MASTER.MASTER_API.ITEM_MASTER_LIST , data,config);
      if (response && response.statusFlag === 3) {
          toast.error(response.message, { autoClose: 2000 });
          localStorage.removeItem("authUser");
          window.location.reload();
          return;
      }
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();
          if (refresh) {
              if (Number(refresh) === constants.invalidRefreshToken) {
                  logoutUser();
              } else {
                  await getItemList();
              }
          }
      }
      if (response?.data?.body?.itemlist) {
        setitemlist(response?.data?.body?.itemlist);
      } else {
          toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
      setLoading(false);
  } catch (error) {
      setLoading(false);
      console.error("Item List API Error:", error);
  }
}
  useEffect(() => {
    document.getElementById('headerTitle').innerHTML = 'Item';
    getItemGroupList()
    getUnitList()
    getItemList()
  }, []);
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content" style={{ height: '100%' }}>
        <div className="container-fluid">
          {/* <Breadcrumbs title="Masters" breadcrumbItem="Exam Board" /> */}
          <Row>
            {isLoading ?
              <Spinners setLoading={setLoading} />
              :
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={itemlist || []}
                      isGlobalFilter={true}
                      isPagination={true}
                      isAddButton={canCreate}
                      buttonName="Add"
                      SearchPlaceholder="Search..."
                      pagination="pagination"
                      handleUserClick={handleAddClick}
                     // isCustomPageSize={true}
                      buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"
                      tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                      theadClass="table-light"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    />
                  </CardBody>
                </Card>
              </Col>
            }
          </Row>
          <Modal isOpen={modal} toggle={toggle} backdrop="static">
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Item" : "Add Item"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }} >
                <Row>
                  <Col className="col-12">
                  <div className="mb-3">
                      <Label>Item Group<span style={{ color: 'red' }}>*</span></Label>
                      <Select
                        value={selectedgroup}
                        onChange={(selectedOption) => {
                          handleSelectGroup(selectedOption);
                          validation.setFieldValue("selectedgroup", selectedOption)
                        }}
                        options={itemgrouplist.map((itemgroup) => ({
                          label: itemgroup.itemgroupname,
                          value: itemgroup.prgid,
                        }))}
                        ref={inputRef}
                        validate={{
                          required: { value: true },
                        }}
                        className={`select2-selection ${validation.touched.selectedgroup && validation.errors.selectedgroup ? 'is-invalid' : ''}`}
                        invalid={
                          validation.touched.selectedgroup && validation.errors.selectedgroup ? true : false
                        }
                      />
                      {validation.touched.selectedgroup && validation.errors.selectedgroup ? (
                        <FormFeedback type="invalid">{validation.errors.selectedgroup}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label>Item Name<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        name="itemname"
                        type="text"
                        placeholder="Item Name"
                        validate={{ required: { value: true } }}
                        maxLength={constants.textboxLength.itemname}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        autoComplete="off"
                        value={validation.values.itemname || ""}
                        invalid={validation.touched.itemname && validation.errors.itemname}
                        // innerRef={inputRef}
                      />
                      {validation.touched.itemname && validation.errors.itemname ? (
                        <FormFeedback type="invalid">{validation.errors.itemname}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Unit<span style={{ color: 'red' }}>*</span></Label>
                      <Select
                        value={selectedunit}
                        onChange={(selectedOption) => {
                            handleSelectunit(selectedOption);
                          validation.setFieldValue("selectedunit", selectedOption)
                        }}
                        options={unitlist.map((unit) => ({
                          label: unit.symbol,
                          value: unit.utid,
                        }))}
                        // ref={inputRef}
                        validate={{
                          required: { value: true },
                        }}
                        className={`select2-selection ${validation.touched.selectedunit && validation.errors.selectedunit ? 'is-invalid' : ''}`}
                        invalid={
                          validation.touched.selectedunit && validation.errors.selectedunit ? true : false
                        }
                      />
                      {validation.touched.selectedunit && validation.errors.selectedunit ? (
                        <FormFeedback type="invalid">{validation.errors.selectedunit}</FormFeedback>
                      ) : null}
                    </div>
                    {isEdit && (   
                        <div className="mb-3">
                            <Label>Status</Label>
                            <div className="square-switch">
                                <input
                                    type="checkbox"
                                    id="statusToggle"
                                    className="switch switch-info"
                                    checked={status1}
                                    onClick={toggleStatus}
                                />
                                <label htmlFor="statusToggle" data-on-label="Active" data-off-label="Inactive" />
                            </div>
                        </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        color="success"
                        type="submit"
                        className="save-user"
                        onClick={() => setSubmitbtn('savebtn')}
                        disabled={isButtonDisabled}

                      >
                        {!!isEdit ? "Update" : "Save"}
                      </Button>
                      {!isEdit && (
                        <Button
                          color="primary"
                          type="submit"
                          className="save-and-continue-user"
                          onClick={() => setSubmitbtn('savecontinue')}
                          style={{ marginLeft: '10px' }}
                          disabled={isButtonDisabled}>
                          Save & Continue
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </React.Fragment>
  );
}

export default ItemMaster;