import React, { useEffect, useMemo, useState, useRef } from "react";
import Select from "react-select";
import TableContainer from '../../../components/Common/TableContainer';
import { ToastContainer,toast } from "react-toastify";
import Spinners from "../../../components/Common/Spinner";
import { getLoggedInUser, getRefreshToken } from "../../../helpers/fakebackend_helper"
import * as constants from "../../../constants/layout"
import { Button, Col, Row, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Form, Input, FormFeedback, Label, Card, CardBody, FormGroup, Badge } from "reactstrap";
import './pricechange.scss'
import "../../../assets/scss/tabledesign.scss";
import * as API from '../../../api/reportsapi';
import * as API_MASTER from '../../../api/masterapi';
import axios from 'axios';
const PriceChange = () => {
  document.title = "Masters | Price Change";  
  const logoutUser = async()=>{
    localStorage.removeItem('userDetails');
    setTimeout(() => navigate('/'), 700);
  };
  const inputRef = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const user = getLoggedInUser();
  const [pricelist,setpricelist] =useState([]);
  const [itemgrouplist,setItemgroupList] =useState([]);
  const [lastupdated,setlastupdated] =useState([]);
  const [selectedItemGroup, setselectedItemGroup] = useState({
    label: "All",
    value: 0
  });
  const [allPricelist,setAllPricelist] =useState([]);
  function handleSelectItemGroup(selectedItemGroup) {
    setselectedItemGroup(selectedItemGroup); }
  useEffect(() => {
    document.getElementById('headerTitle').innerHTML = 'Price Change';
  }, []);

  const udfnPriceChange = async () => { 
		setLoading(true);
		const user = getLoggedInUser();
    var apiUrl =  API_MASTER.MASTER_API.PRICE_CHANGE;
		var uploadData = {
      user_id: Number(user.uid),
      pricelist: pricelist,
      loginfrom:10
		}
		const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
    const response = await axios.post(process.env.REACT_APP_BASE_URL +apiUrl , uploadData,config);
    try {
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();
          if (refresh) {
              if (Number(refresh) === constants.invalidRefreshToken) {
                  logoutUser();
              } else {
                  await udfnPriceChange();
              }
          }
      }    
      if (response && response?.data?.body?.status == '200' || response && response?.data?.body?.status == 200) {       
        setLoading(false);
        toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
        getPriceList();
      } else {
        setLoading(false);
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
		} catch (error) {
			setLoading(false);
      toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
		}
	}; 
const handleChange = (prid,field, value,newRow) => { 
  newRow = {
  newprice:value
}
  setpricelist((prev) =>
    prev.map((row) => (row.prid === prid ? { ...row, ...newRow } : row))
);
};
  const columns = useMemo(
    () => [
      {
        header: 'S.No.',
        width: '8%',
        enableColumnFilter: false,
        className: 'pl-25',
        cell: ({ row, table }) =>
          (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Item Name ▼
          </span>
        ),
        accessorKey: 'itemname',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Item Group ▼
          </span>
        ),
        accessorKey: 'itemgroupname',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Current Price ▼
          </span>
        ),
        accessorKey: 'price',
        enableColumnFilter: false,
        enableSorting: true, 
        cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-end">
                  {cellProps?.row?.original?.price}
            </div>
          );
        }
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            New Price ▼          </span>
        ),
        accessorKey: 'newprice',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <Input
            className="txt-right"
            name="newprice"
            type="number"
            placeholder="New Price"
            maxLength={constants.textboxLength.new_price}
            onKeyPress={(e) => {
              if (e.key === '-' || e.key === 'e') {
                e.preventDefault();
              }
            }}
            onPaste={(e) => {
              e.preventDefault();
              const pastedText = e.clipboardData.getData('text');
              let pastedValue = parseFloat(pastedText);
              if (isNaN(pastedValue) && pastedValue < 0) {
                pastedValue = "";
              }
              e.target.value = pastedValue.toString().slice(0, 3);
            }}
            validate={{ required: { value: true } }}
            onChange={e=>{
            handleChange(cellProps.row.original.prid,'newprice',e.target.value,cellProps.row.original)}}
            // onFocus={e=>{
            //   handleFocus(cellProps.row.original.prid)}}
           // onBlur={validation.handleBlur}
            value={cellProps.row.original.newprice || "0"}
           // invalid={validation.touched.newprice && validation.errors.newprice}
           // innerRef={inputRef}
            />
          );
        }
      },
      // {
      //   header: (
      //     <span style={{ cursor: 'pointer' }}>
      //     Last Updated
      //   </span>
      //   ),
      //   accessorKey: 'updated',
      //   enableColumnFilter: false,
      //   enableSorting: false,
      //   cell: (cellProps) => {
      //     return (
      //       <div className="created">
      //             {cellProps?.row?.original?.updated}<br/>
      //             {cellProps?.row?.original?.updatedon}
      //       </div>
      //     );
      //   }
      // }
    ],
    []
  );
  useEffect(() => {
    document.getElementById('headerTitle').innerHTML = 'Price Change';    
  getItemGroupList()
  getPriceList()
  }, []);
  const getItemGroupList = async () => {
    setLoading(true);
    const data = { user_id: Number(user.uid),
      loginfrom:10 };
   const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
    try {
      const response = await axios.post(process.env.REACT_APP_BASE_URL +API_MASTER.MASTER_API.ITEMGROUP_MASTER_LIST , data,config);
        if (response && response.statusFlag === 3) {
            toast.error(response.message, { autoClose: 2000 });
            localStorage.removeItem("authUser");
            window.location.reload();
            return;
        }
        if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
            const refresh = await getRefreshToken();
            if (refresh) {
                if (Number(refresh) === constants.invalidRefreshToken) {
                    logoutUser();
                } else {
                    await getItemGroupList();
                }
            }
        }
        if (response?.data?.body?.itemgrouplist) {
         response?.data?.body?.itemgrouplist.unshift({
            itemgroupname: "All",
            prgid: 0
          })
          setItemgroupList(response?.data?.body?.itemgrouplist);
        } else {
            toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
        }
        setLoading(false);
    } catch (error) {
        // Handle error
        setLoading(false);
        console.error("Item Group List API Error:", error);
    }
  }
  async function getPriceList() {
    setLoading(true);
    const data = {
        user_id: Number(user.uid),
        prgid: Number(selectedItemGroup.value),
        loginfrom:10
    };
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
    try {
      const response = await axios.post(process.env.REACT_APP_BASE_URL + API.REPORTS_API.REPORTS_PRICELIST_API , data, config);
        if (response && response.statusFlag === 3) {
            toast.error(response.message, { autoClose: 2000 });
            localStorage.removeItem("authUser");
            window.location.reload();
            return;
        }
        if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
            const refresh = await getRefreshToken();
            if (refresh) {
                if (Number(refresh) === constants.invalidRefreshToken) {
                    logoutUser();
                } else {
                    await getPriceList();
                }
            }
        }
        if (response?.data?.body?.pricelist) {
          setpricelist(response.data.body.pricelist);
          setAllPricelist(response.data.body.pricelist);
          var lastupdatedvalue = "-";
          var lastupdate = response.data.body.pricelist[0].updated;
          if(lastupdate =="-" || lastupdate == null || lastupdate == undefined){lastupdatedvalue = "-"}
          else{lastupdatedvalue = response.data.body.pricelist[0].updated + " @ "+response.data.body.pricelist[0].updatedon}
          setlastupdated(lastupdatedvalue);
        } else {
            toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
        }
        setLoading(false);
    } catch (error) {
      setLoading(false);
        console.error("Price List API Error:", error);
    }
  }
  return (
    <React.Fragment>
      <div className="page-content" style={{ height: '100%' }}>
        <div className="container-fluid">
          {/* <Breadcrumbs title="Masters" breadcrumbItem="Exam Board" /> */}
          <Row>
            {isLoading ?
              <Spinners setLoading={setLoading} />
              :
              <Col xs="12">
                <Card>
                  <CardBody>
                  <Row className="g-3">
                      <Col lg={4} >                        
                      </Col>
                        <Row>
                          <Col lg={3}>
                            <div className="mb-3">
                              <Label>Item Group</Label>
                              <Select
                                value={selectedItemGroup}
                                onChange={(selectedOption) => {
                                  handleSelectItemGroup(selectedOption);
                                }}
                                options={itemgrouplist.map((option) => ({
                                  label: option.itemgroupname,
                                  value: option.prgid,
                                }))}
                                className="select2-selection"
                              />
                            </div>
                          </Col>
                          <Col lg={1} >
                              <div className="d-flex flex-wrap gap-2 justify-content-start">
                                <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-soft-info"
                                  onClick={() => getPriceList()}>
                                  Apply
                                </Button>
                              </div>
                            </Col>
                            <Col lg={5} ></Col>
                            <Col lg={3} >
                                <div className='d-flex flex-wrap gap-2 justify-content-start'>
                                <Label>Last Updated :</Label><br/>
                                <Label style={{bold:true}}>{lastupdated}</Label>
                                </div>
                            </Col>
                        </Row>
                    </Row>
                    <TableContainer
                      columns={columns}
                    
                      data={pricelist || []}
                     // isGlobalFilter={true}
                     // isPagination={true}
                    //  isAddButton={canCreate}
                      buttonName="Add"
                    //  SearchPlaceholder="Search..."
                      pagination="pagination"
                     // handleUserClick={handleAddClick}
                     // isCustomPageSize={true}
                      buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"
                      tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                      theadClass="table-light"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    />
                  </CardBody>
                </Card>
              </Col>
            }
          </Row>
          <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        color="success"
                        type="submit"
                        className="save-user"
                        onClick={() => udfnPriceChange()}
                       // disabled={isButtonDisabled}
                      > Update</Button>
                    </div>
                  </Col>
                </Row>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </React.Fragment>
  );
}

export default PriceChange;