const REPORTS_API = {
    // pricelist api url
    REPORTS_PRICELIST_API: "api/masters/listprice",

    //Outward date-wise report
    REPORTS_OUTWARD_DATEWISE: "api/outward/listOutward",
    //Inward date-wise report    
    REPORTS_INWARD_DATEWISE: "api/inward/listInward",

    // Outward party-wise report
    REPORTS_OUTWARD_PARTYWISE: "api/reports/outwardreport_partywise",
    //Inward party-wise report
    REPORTS_INWARD_PARTYWISE: "api/reports/inwardreport_partywise",

};

export{ REPORTS_API }
    