import React, { useEffect, useMemo, useState, useRef } from "react";
import Select from "react-select";
import { ToastContainer,toast } from "react-toastify";
import Spinners from "../../components/Common/Spinner";
import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper"
import * as constants from "../../constants/layout"
import {Button, Col, Row, InputGroup, Label, Card, CardBody, FormGroup, CardTitle, CardText,Badge,UncontrolledTooltip, Table } from "reactstrap";
import "../../components/Common/common.scss";
import "../Reports/datewise.scss";
import { MaterialReactTable, useMaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { Box, Typography } from '@mui/material';
import * as API_MASTER from '../../../src/api/masterapi';
import * as API_REPORTS from '../../api/reportsapi';
import axios from 'axios';
import moment from 'moment';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { color } from "echarts";

// const logoutUser = async()=>{
//   localStorage.removeItem('userDetails');
//   setTimeout(() => navigate('/'), 700);
// };
const OutwardDateWiseReport = () => {
  document.title = "Reports | Outward Date-wise Report";
  const [isLoading, setLoading] = useState(false);
  const user = getLoggedInUser();
  const [outwardlist,setoutwardlist] =useState([]);
  const [partylist,setpartylist] =useState([]);
  const [itemlist,setitemlist] =useState([]);
  const [totalqty,settotalqty] =useState('0');
  const [totalamount,settotalamount] =useState('0');
  const ExcelJS = require('exceljs');
  const [selectedDates, setSelectedDates] = useState([
    new Date(),
    new Date()
]);
const [selectedPartyName, setselectedPartyName] = useState({
  label: "All",
  value: 0
});
const [selectedItemName, setselectedItemName] = useState({
  label: "All",
  value: 0
});
const formatter = new Intl.NumberFormat('en-IN', {maximumSignificantDigits: 3 })
const flatpickrRef = React.useRef(null);
  const handleDateChange = (selectedDates) => {
    setSelectedDates(selectedDates);
    if (selectedDates[0] != null && selectedDates[0] != undefined && selectedDates[1] != null && selectedDates[0] != undefined) {
      const flatpickrInstance = flatpickrRef.current.flatpickr;
      if (flatpickrInstance) {
          flatpickrInstance.close();
      }
      // getPartyList()
  }
};

useEffect(() => {
  if (selectedDates[0] != null && selectedDates[0] != undefined && selectedDates[1] != null && selectedDates[0] != undefined) {
    getPartyList()
}
}, [selectedDates[1]]); 

function getCommaSeparatedTwoDecimalsNumber(number) {
  const fixedNumber = Number.parseFloat(number).toFixed(2);
  return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

}

// const columns = useMemo(
//   () => [
//     // {
//     //   header: 'S.No.',
//     //   width: '8%',
//     //   enableColumnFilter: false,
//     //   className: 'pl-25',
//     //   cell: ({ row, table }) =>
//     //     (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
//     // },
//     {
//       header: (
//         <span style={{ cursor: 'pointer' }}>
//           Voucher No. ▼
//         </span>
//       ),
//       accessorKey: 'id',
//       enableColumnFilter: false,
//       enableSorting: true,
//     },
//     {
//       header: (
//         <span style={{ cursor: 'pointer' }}>
//           Party Name ▼
//         </span>
//       ),
//       accessorKey: 'name',
//       enableColumnFilter: false,
//       enableSorting: true,
//     },
//     // {
//     //   header: (
//     //     <span style={{ cursor: 'pointer' }}>
//     //       No.of Items
//     //     </span>
//     //   ),
//     //   accessorKey: 'totalitems',
//     //   enableColumnFilter: false,
//     //   enableSorting: true,
//     // }, 
//     // {
//     //   header: (
//     //     <span style={{ cursor: 'pointer' }}>
//     //       Total Amount
//     //     </span>
//     //   ),
//     //   accessorKey: 'price',
//     //   enableColumnFilter: false,
//     //   enableSorting: true, 
//     //   cell: (cellProps) => {
//     //     return (
//     //       <div className="d-flex justify-content-end">
//     //             {cellProps?.row?.original?.totalamount}
//     //       </div>
//     //     );
//     //   }
//     // }
//   ],
//   []
// ); 
const columns = [
  {
    accessorKey: 'voucherno',
    header: 'Voucher No.',
  },
  {
    accessorKey: 'voucherdate',
    header: 'Voucher Date.',
    enableColumnFilter: false 
  },
  {
    accessorKey: 'partyname',
    header: 'Party Name',
  },
  {
    accessorKey: 'noofitems',
    header: 'No.of Items',
  }, 
  {
    accessorKey: 'totalamount',
    header: 'Total Value',
      Cell: ({ cell }) => 
        <div style={{textAlign: 'right'}}>
          {/* <span>{parseFloat(cell.getValue()).toFixed(2)}</span> */}
          <span><label>₹ </label> {getCommaSeparatedTwoDecimalsNumber(cell.getValue())}</span>
        </div>
    }, 
    {
      accessorFn: (row) => 
        <div style={{textAlign: 'right', lineHeight: 2}}>
           {row.cashamount != 0 ? <span style={{ padding: 5, borderRadius: 10}}><label>₹ </label> {getCommaSeparatedTwoDecimalsNumber(row.cashamount)}{' '}<span style={{backgroundColor: 'green', color: 'white', padding: 5, borderRadius: 10}}>{'Cash'}</span><br></br></span>: null}
            
           {row.upiamount != 0 ? <span style={{ padding: 5, borderRadius: 10}}><label>₹ </label> {getCommaSeparatedTwoDecimalsNumber(row.upiamount)}{' '}<span style={{backgroundColor: 'blue', color: 'white', padding: 5, borderRadius: 10}}>{'UPI'}</span></span>: null}
                
                
          </div>,
          
      header: 'Receipt Amount'
      }
];
const nestedColumns = [
  {
    accessorKey: 'productname',
    header: 'Item Name',
  },
  {
    accessorFn: (row) => 
      <div style={{textAlign: 'right'}}>
              {parseFloat(row.qty).toFixed(row.noofdecimal)}
        </div>,
    // accessorKey: 'qty',
    header: 'Quantity',
    // Cell: ({ cell }) => 
    //   <div style={{textAlign: 'right'}}>
    //     <span>{parseFloat(cellProps?.row?.original?.rate).toFixed(cellProps?.row?.original?.noofdecimals)}</span>
    //   </div>
    // cell: (cellProps) => {
    //   return (
    //     <div style={{textAlign: 'left'}}>
    //           {parseFloat(cellProps?.row?.original?.rate).toFixed(cellProps?.row?.original?.noofdecimals)}
    //     </div>
    //   );
    // }
  },
  {
    accessorKey: 'unit',
    header: '',
    // cell: (cellProps) => {
    //   return (
    //     <div className="text-left">
    //           {parseFloat(cellProps?.row?.original?.rate).toFixed(2)}
    //     </div>
    //   );
    // }
    Cell: ({ cell }) => 
      <div style={{textAlign: 'left'}}>
          <span>{cell.getValue()}</span>
        </div>
  },
  {
    accessorKey: 'rate',
    header: 'Price',
    // cell: (cellProps) => {
    //   return (
    //     <div className="text-right">
    //           {parseFloat(cellProps?.row?.original?.rate).toFixed(2)}
    //     </div>
    //   );
    // }
    Cell: ({ cell }) => 
      <div style={{textAlign: 'right'}}>
          <span>{parseFloat(cell.getValue()).toFixed(2)}</span>
        </div>
  },  
  {
    accessorKey: 'amount',
    header: 'Total Value',
    // cell: (cellProps) => {
    //   return (
    //     <div className="text-right">
    //           {parseFloat(cellProps?.row?.original?.amount).toFixed(2)}
    //     </div>
    //   );
    // }
    Cell: ({ cell }) => 
      <div style={{textAlign: 'right'}}>
          <span>{parseFloat(cell.getValue()).toFixed(2)}</span>
        </div>
  },
];
  const exportToExcel = async () => {
    let varParty =selectedPartyName.label;
    let varItem =selectedItemName.label;
      //setIsLoading(true)
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Data')
      worksheet.mergeCells('A1', 'G1')
      const topRow = worksheet.getCell('A1')
      // Add headers with styling
      topRow.value="Outward Date-wise Report ("+moment(selectedDates[0]).format("DD-MM-YYYY")+" - "+moment(selectedDates[1]).format("DD-MM-YYYY")+") ";
      topRow.alignment = { vertical: 'center', horizontal: 'center' }
    topRow.font = { bold: true }
    topRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'e5de00' } // Background color
    } 
    const secondRow = worksheet.getCell('A2')
    secondRow.value="Party : ";
    secondRow.alignment = { vertical: 'center', horizontal: 'right' }
    secondRow.font = { bold: true }
    secondRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'F9DBBA' } 
    }
    const secondRow2 = worksheet.getCell('B2')
    secondRow2.value=varParty;
    secondRow2.alignment = { vertical: 'center', horizontal: 'left' }
    secondRow2.font = { bold: true }
    secondRow2.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'F9DBBA' } 
    }
    const secondRow5 = worksheet.getCell('C2')
    secondRow5.value="";
    secondRow5.alignment = { vertical: 'center', horizontal: 'center' }
    secondRow5.font = { bold: true }
    secondRow5.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'F9DBBA' } 
    }
    const secondRow6 = worksheet.getCell('D2')
    secondRow6.value="";
    secondRow6.alignment = { vertical: 'center', horizontal: 'center' }
    secondRow6.font = { bold: true }
    secondRow6.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'F9DBBA' } 
    }
    const secondRow7 = worksheet.getCell('E2')
    secondRow7.value="";
    secondRow7.alignment = { vertical: 'center', horizontal: 'center' }
    secondRow7.font = { bold: true }
    secondRow7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'F9DBBA' } 
    }
    const secondRow3 = worksheet.getCell('F2')
    secondRow3.value="Item Name : ";
    secondRow3.alignment = { vertical: 'center', horizontal: 'right' }
    secondRow3.font = { bold: true }
    secondRow3.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'F9DBBA' } 
    }
    const secondRow4 = worksheet.getCell('G2')
    secondRow4.value=varItem;
    secondRow4.alignment = { vertical: 'center', horizontal: 'left' }
    secondRow4.font = { bold: true }
    secondRow4.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'F9DBBA' } 
    }
    const thirdrow1 = worksheet.getCell('A3')
    thirdrow1.value="Total Quantity :";
    thirdrow1.alignment = { vertical: 'center', horizontal: 'right' }
    thirdrow1.font = { bold: true }
    thirdrow1.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFAD60' } 
    }
    const thirdrow2 = worksheet.getCell('B3')
    thirdrow2.value=totalqty;
    thirdrow2.alignment = { vertical: 'center', horizontal: 'left' }
    thirdrow2.font = { bold: true }
    thirdrow2.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFAD60' } 
    }
    const thirdrow3 = worksheet.getCell('C3')
    thirdrow3.value="";
    thirdrow3.alignment = { vertical: 'center', horizontal: 'center' }
    thirdrow3.font = { bold: true }
    thirdrow3.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFAD60' } 
    }
    const thirdrow4 = worksheet.getCell('D3')
    thirdrow4.value="";
    thirdrow4.alignment = { vertical: 'center', horizontal: 'center' }
    thirdrow4.font = { bold: true }
    thirdrow4.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFAD60' } 
    }
    const thirdrow5 = worksheet.getCell('E3')
    thirdrow5.value="";
    thirdrow5.alignment = { vertical: 'center', horizontal: 'center' }
    thirdrow5.font = { bold: true }
    thirdrow5.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFAD60' } 
    }
    const thirdrow6 = worksheet.getCell('F3')
    thirdrow6.value="Total Value :";
    thirdrow6.alignment = { vertical: 'center', horizontal: 'right' }
    thirdrow6.font = { bold: true }
    thirdrow6.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFAD60' } 
    }
    const thirdrow7 = worksheet.getCell('G3')
    thirdrow7.value=totalamount;
    thirdrow7.alignment = { vertical: 'center', horizontal: 'left' }
    thirdrow7.font = { bold: true }
    thirdrow7.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFAD60' } 
    }
    
    const headercell1 = worksheet.getCell('A4')
    headercell1.value="";
    headercell1.alignment = { vertical: 'center', horizontal: 'center' }
    headercell1.font = { bold: true }
    headercell1.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'c0d5e8' } // Background color
    }
    const headercell11 = worksheet.getCell('B4')
    headercell11.value="S.No.";
    headercell11.alignment = { vertical: 'center', horizontal: 'center' }
    headercell11.font = { bold: true }
    headercell11.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'c0d5e8' } // Background color
    }
    const headercell2 = worksheet.getCell('C4')
    headercell2.value="Item Name";
    headercell2.alignment = { vertical: 'center', horizontal: 'center' }
    headercell2.font = { bold: true }
    headercell2.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'c0d5e8' } // Background color
    }
    const headercell4 = worksheet.getCell('D4')
    headercell4.value="Quantity";
    headercell4.alignment = { vertical: 'center', horizontal: 'center' }
    headercell4.font = { bold: true }
    headercell4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'c0d5e8' } // Background color
    }
    const headercell5 = worksheet.getCell('E4')
    headercell5.value="Unit";
    headercell5.alignment = { vertical: 'center', horizontal: 'center' }
    headercell5.font = { bold: true }
    headercell5.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'c0d5e8' } // Background color
    }
    const headercell6 = worksheet.getCell('F4')
    headercell6.value="Price";
    headercell6.alignment = { vertical: 'center', horizontal: 'center' }
    headercell6.font = { bold: true }
    headercell6.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'c0d5e8' } // Background color
    }
    const headercell7 = worksheet.getCell('G4')
    headercell7.value="Total Value";
    headercell7.alignment = { vertical: 'center', horizontal: 'center' }
    headercell7.font = { bold: true }
    headercell7.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'c0d5e8' } // Background color
    }
      // Add data
      outwardlist.forEach((item, index) => {
         const headerRow1 = worksheet.addRow([
              item.voucherno,
              item.voucherdate,
              item.partyname,"","","",
              item.totalamount
          ])
          headerRow1.font = { bold: true }
          headerRow1.eachCell({ includeEmpty: true }, (cell) => {
            cell.fill = {
               type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '8fcc45' }, // Background color
              color:{argb:'FFFFFF'}
            };
          });
          // headerRow1.fill = {
          //     type: 'pattern',
          //     pattern: 'solid',
          //     fgColor: { argb: '8fcc45' }, // Background color
          //     color:{argb:'FFFFFF'}
          // }
          item.items.forEach((inneritem, newindex) => {
            worksheet.addRow([
              "",
              newindex + 1,
                inneritem.productname,
                inneritem.qty,
                inneritem.unit,
                inneritem.rate,
                inneritem.amount
            ])
        })
      })
      // Save the workbook
      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `Outward Date-wise Report.xlsx`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      // setIsLoading(false)
      // setExcelButtonFlag(false)
  }  
  useEffect(() => {
    document.getElementById('headerTitle').innerHTML = 'Outward Date-wise Report';
  }); 
function handleSelectPartyName(selectedPartyName) {
    setselectedPartyName(selectedPartyName); }
useEffect(()=> {
  getPartyList()
  getItemList()
  getOutwardList()
},[])

function handleSelectItemName(selectedItemName) {
  setselectedItemName(selectedItemName); }

const getPartyList = async () => {
  setLoading(true);
  const data = { type: 1,user_id: Number(user.uid),
    loginfrom:10, fromdate: moment(selectedDates[0]).format("YYYY-MM-DD"),todate:moment(selectedDates[1]).format("YYYY-MM-DD")  };
 const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_URL +API_MASTER.MASTER_API.PARTY_MASTER_LIST , data,config);
      if (response && response.statusFlag === 3) {
          toast.error(response.message, { autoClose: 2000 });
          localStorage.removeItem("authUser");
          window.location.reload();
          return;
      }
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();
          if (refresh) {
              if (Number(refresh) === constants.invalidRefreshToken) {
                  logoutUser();
              } else {
                  await getPartyList();
              }
          }
      }
      if (response?.data?.body?.partylist) {
        response?.data?.body?.partylist.unshift({
          partyname: "All",
          ptid: 0
        })
        setpartylist(response?.data?.body?.partylist);
      } else {
          toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
      setLoading(false);
  } catch (error) {
      setLoading(false);
      console.error("Party List API Error:", error);
  }
}


const getItemList = async () => {
  setLoading(true);
  const data = { user_id: Number(user.uid),
    loginfrom:10 };
 const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_URL +API_MASTER.MASTER_API.ITEM_MASTER_LIST , data,config);
      if (response && response.statusFlag === 3) {
          toast.error(response.message, { autoClose: 2000 });
          localStorage.removeItem("authUser");
          window.location.reload();
          return;
      }
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();
          if (refresh) {
              if (Number(refresh) === constants.invalidRefreshToken) {
                  logoutUser();
              } else {
                  await getItemList();
              }
          }
      }
      if (response?.data?.body?.itemlist) {
        response?.data?.body?.itemlist.unshift({
          itemname: "All",
          prid: 0
        })
        setitemlist(response?.data?.body?.itemlist);
      } else {
          toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
      setLoading(false);
  } catch (error) {
      setLoading(false);
      console.error("Party List API Error:", error);
  }
}
const getOutwardList = async () => {
  setLoading(true);
  const data = { user_id: Number(user.uid),ptid:Number(selectedPartyName?.value),fromdate:moment(selectedDates[0]).format("YYYY-MM-DD"),todate:moment(selectedDates[1]).format("YYYY-MM-DD"),productid:Number(selectedItemName?.value),
    loginfrom:10};
 const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_URL +API_REPORTS.REPORTS_API.REPORTS_OUTWARD_DATEWISE , data,config);
      if (response && response.statusFlag === 3) {
          toast.error(response.message, { autoClose: 2000 });
          localStorage.removeItem("authUser");
          window.location.reload();
          return;
      }
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();
          if (refresh) {
              if (Number(refresh) === constants.invalidRefreshToken) {
                  logoutUser();
              } else {
                  await getOutwardList();
              }
          }
      }
      if (response?.data?.body?.outwardlist) {
        setoutwardlist(response?.data?.body?.outwardlist);
        settotalqty(response?.data?.body?.totalqty);
        settotalamount(response?.data?.body?.totalamount);
      } else {
          toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
      setLoading(false);
  } catch (error) {
      setLoading(false);
      console.error("Outward date-wise report API Error:", error);
  }
}
  return (
    <React.Fragment>
      <div className="page-content" style={{ height: '100%' }}>
        <div className="container-fluid">
          {/* <Breadcrumbs title="Masters" breadcrumbItem="Exam Board" /> */}
          <Row>
            {isLoading ?
              <Spinners setLoading={setLoading} />
              :
              <Col lg={12}>
                <Card>
                  <CardBody>
                  <Row>
            <Col lg={12}>
                    <Row className="g-3">
                      <Col lg={4} >                        
                      </Col>
                        <Row>                             
                        <Col lg={3}>
                        <div className="mb-3">
                            <FormGroup className="mb-4">
                                <Label>Period</Label>
                                <InputGroup>
                                  <Flatpickr data-enable-time
                                  ref={flatpickrRef}
                                      value={selectedDates}
                                      className="form-control d-block"
                                      options={{
                                          mode: "range",
                                          dateFormat: "d-m-Y",
                                          onChange: handleDateChange // Attach onChange handler to update state
                                      }}
                                  />
                                  <button className="btn btn-light" type="button" id="calendar-addon">
                                  <i className="bx bx-calendar-event"></i>
                              </button>
                              </InputGroup>
                                {/* <div>
                                    Selected Dates: {selectedDates && selectedDates.length > 0 ? selectedDates.map(date => date.toLocaleDateString()).join(' - ') : 'Select a date range'}
                                </div> */}
                            </FormGroup>
                        </div>
                    </Col>
                    <Col lg={3}>
                            <div className="mb-3">
                              <Label>Party Name</Label>
                              <Select
                                value={selectedPartyName}
                                onChange={(selectedOption) => {
                                  handleSelectPartyName(selectedOption);
                                }} 
                                options={partylist.map((option) => ({
                                  label: option.partyname,
                                  value: option.ptid,
                                }))}
                                className="select2-selection"
                                />
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="mb-3">
                              <Label>Item Name</Label>
                              <Select
                                value={selectedItemName}
                                onChange={(selectedOption) => {
                                  handleSelectItemName(selectedOption);
                                }} 
                                options={itemlist.map((option) => ({
                                  label: option.itemname,
                                  value: option.prid,
                                }))}
                                className="select2-selection"
                                />
                            </div>
                          </Col>
                          <Col lg={1} >
                              <div className="d-flex flex-wrap gap-2 justify-content-start">
                                <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-soft-info"
                                  onClick={() => getOutwardList()}>
                                  Apply
                                </Button>
                              </div>
                            </Col>
                            <Col lg={2} >
                                <div className='d-flex flex-wrap gap-2 justify-content-start'>
                                <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-soft-info"
                                //   onClick={() => exportToExcel()} disabled={excelButtonFlag ? true : false}>
                                    onClick={() => exportToExcel()} >
                                <i className="fa fa-download" /> &nbsp; Export
                                </Button>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="mini-stats-wid btn-soft-info btn-secondary">
                                <CardBody>
                                <div className="d-flex align-items-center"><div className="avatar-xs me-3">
                                    <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-18">
                                      <i className={"bx bxl-product-hunt"}></i></span></div><h5 className="font-size-14 mb-0" style={{bold:'true'}}>{parseFloat(totalqty).toFixed(2)}</h5></div>
                                </CardBody>
                                </div>
                            </Col>
                             <Col lg={3}>
                                <div className="mini-stats-wid btn-soft-info btn-secondary">
                                <CardBody>
                                <div className="d-flex align-items-center"><div className="avatar-xs me-3">
                                    <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-18">
                                      <i className={"bx bx-rupee"}></i></span></div><h5 className="font-size-14 mb-0" style={{bold:'true'}}>{parseFloat(totalamount).toFixed(2)}</h5></div>
                                </CardBody>
                                </div>
                            </Col>
                        </Row>
                    </Row>               
            </Col>
          </Row>
            <MaterialReactTable
             options={{
              exportButton: true,
              exportAllData: true
              }}
        columns={columns}
        data={outwardlist}
        enableSorting={true}
        enableColumnFilters={false}
        enableGlobalSearch={false}
        muiToolbar={() => null} 
        enableTopToolbar={false}
        enableBottomToolbar= {false}
        enableColumnFilterModes={false}
        enableColumnActions={false}
        enablePagination={false}
        enableToolbarInternalActions={false}
        renderDetailPanel={({ row }) => (
          <Box sx={{ pl: 25, pr: 25 }}>
            <MaterialReactTable
              columns={nestedColumns}
              data={row.original.items}
              enableSorting={false} // Disable sorting for nested table if needed
              enablePagination={false} // Disable pagination for nested table if needed
              enableColumnFilters={false}
              enableColumnFilterModes={false}
              enableTopToolbar={false}
              enableColumnActions={false}
              className="table-design"
              />
          </Box>
        )}
          // Custom toolbar to avoid default search and other controls
      renderTopToolbarCustomActions={() => (
        <Box sx={{ p: 1}}>
        </Box>
      )} />
                  </CardBody>
                </Card>
                </Col>                
            }
          </Row>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </React.Fragment>
  );
}

export default OutwardDateWiseReport;
