import React, { useEffect, useMemo, useState, useRef } from "react";
import Select from "react-select";
import * as Yup from "yup";
import TableContainer from '../../../components/Common/TableContainer';
import { ToastContainer, toast } from "react-toastify";
import Spinners from "../../../components/Common/Spinner";
import { useFormik } from "formik";
import DeleteModal from "../../../components/Common/DeleteModal";
import { getLoggedInUser, getRefreshToken } from "../../../helpers/fakebackend_helper";
import * as constants from "../../../constants/layout";
import { Button, Col, Row, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Form, Input, FormFeedback, Label, Card, CardBody, CardText, CardTitle, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import "../../../assets/scss/tabledesign.scss";
import "../../../assets/scss/common.scss";
import * as API_MASTER from '../../../api/masterapi';
import axios from 'axios';
import classnames from "classnames";
const User = () => {
  document.title = "Masters | User Management";
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [newpin1, setnewpin1] = useState("");
  const [newpin2, setnewpin2] = useState("");
  const [newpin3, setnewpin3] = useState("");
  const [newpin4, setnewpin4] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const inputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const [submitbtn, setSubmitbtn] = useState('');
  const [deleteTrigger, setDeleteTrigger] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [canCreate, setCanCreate] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const user = getLoggedInUser();
  const [isEditing, setIsEditing] = useState(false);
  const [newpassword, setnewpassword] = useState('')
  const [newpasswordError, SetnewpasswordError] = useState(false)
  const [resetpasswordError, setresetpasswordError] = useState('')
  const [userconfirmpassword, setuserconfirmpassword] = useState('')
  const [conformpasswordError, SetconformpasswordError] = useState('')
  const [conformpassword, setconformpassword] = useState(false)
  const password_validation = constants.errorMessages.PASSWORD_VALIDATION
  const [newpasswordShow, setNewPasswordShow] = useState(false);
  const [confmpasswordShow, setConfmPasswordShow] = useState(false);
  const [confmpasswordShow1, setConfmPasswordShow1] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);

  const [newPIN, setnewPIN] = useState('')
  const [newPINError, SetnewPINError] = useState(false)
  const [resetPINError, setresetPINError] = useState('')
  const [userconfirmPIN, setuserconfirmPIN] = useState('')
  const [conformPINError, SetconformPINError] = useState('')
  const [conformPIN, setconformPIN] = useState(false)
  const PIN_validation = constants.errorMessages.PASSWORD_VALIDATION
  const [newPINShow, setNewPINShow] = useState(false);
  const [confmPINShow, setConfmPINShow] = useState(false);
  const [confmPINShow1, setConfmPINShow1] = useState(false);
  const [PINShow, setPINShow] = useState(false);
  const [selectedlevel, setselectedlevel] = useState(null);
  const [userlist, setuserlist] = useState([]);
  const [levellist, setlevellist] = useState([]);
  const [userdetails, setuserdetails] = useState([]);
  const [status, setstatus] = useState(false);
  let one = React.createRef();
  let two = React.createRef();
  let three = React.createRef();
  let four = React.createRef();
  let newone = React.createRef();
  let newtwo = React.createRef();
  let newthree = React.createRef();
  let newfour = React.createRef();

  const logoutUser = async () => {
    localStorage.removeItem('userDetails');
    setTimeout(() => navigate('/'), 700);
  };
  const udfnSaveUser = async (values) => {
    setLoading(true);
    const user = getLoggedInUser();
    var apiUrl = '';
    var uploadData = {};
    var vardeviceid = "";
    var varloginid = "";
    if (values.deviceid == undefined || values.deviceid == null || values.deviceid == "") { vardeviceid = ""; }
    else { vardeviceid = String(values.deviceid).trim(); }
    if (values.loginid == undefined || values.loginid == null || values.loginid == "") { varloginid = ""; }
    else { varloginid = String(values.loginid).trim(); }
    if (isEdit === true) {
      uploadData = {
        user_id: values.user_id,
        uid: values.uid,
        u_displayname: values.displayname.trim(),
        u_accesslevel: Number(values.accesslevel),
        u_loginid: varloginid,
        //	u_password: String(values.password).trim(),
        u_deviceid: vardeviceid,
        //  u_pin: Number(values.pin),
        u_stsid: Number(values.statusid),
        loginfrom: 10
      };
      apiUrl = API_MASTER.MASTER_API.USER_MASTER_UPDATE;
    } else {
      uploadData = {
        user_id: values.user_id,
        uid: 0,
        u_displayname: values.displayname.trim(),
        u_accesslevel: Number(values.accesslevel),
        u_loginid: varloginid,
        u_password: String(values.password).trim(),
        u_deviceid: vardeviceid,
        u_pin: Number(values.pin),
        loginfrom: 10
      };
      apiUrl = API_MASTER.MASTER_API.USER_MASTER_SAVE;
    }
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
    const response = await axios.post(process.env.REACT_APP_BASE_URL + apiUrl, uploadData, config);
    try {
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken();
        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser();
          } else {
            await udfnSaveUser(values);
          }
        }
      }
      if (response && response?.data?.body?.status == '200' || response && response?.data?.body?.status == 200) {
        if (isEdit === true) {
          setModal(false);
        }
        else {
          if (submitbtn !== 'savecontinue') {
            setModal(false);
          }
        }
        enableButton();
        udfnClear();
        toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
        getUserList();
      } else {
        enableButton();
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      enableButton();
      setLoading(false);
      toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
    }
  };
  const udfnDeleteUser = async (values) => {
    setLoading(true);
    const user = getLoggedInUser();
    var apiUrl = API_MASTER.MASTER_API.USER_MASTER_DELETE;
    var uploadData = {
      user_id: values.user_id,
      uid: Number(values.uid),
      loginfrom: 10
    }
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
    const response = await axios.post(process.env.REACT_APP_BASE_URL + apiUrl, uploadData, config);
    try {
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken();
        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser();
          } else {
            await udfnDeleteUser(values);
          }
        }
      }
      if (response && response?.data?.body?.status == '200' || response && response?.data?.body?.status == 200) {
        setLoading(false);
        toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
        getUserList();
      } else {
        setLoading(false);
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      setLoading(false);
      toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
    }
  };
  const udfnResetPassword = async (values) => {
    setLoading(true);
    const user = getLoggedInUser();
    var apiUrl = API_MASTER.MASTER_API.RESET_PASSWORD;
    var uploadData = {
      user_id: Number(values.user_id),
      uid: Number(values.uid),
      new_pwd: values.new_pwd,
      loginfrom: 10
    }
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
    const response = await axios.post(process.env.REACT_APP_BASE_URL + apiUrl, uploadData, config);
    try {
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken();
        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser();
          } else {
            await udfnResetPassword(values);
          }
        }
      }
      if (response && response?.data?.body?.status == '200' || response && response?.data?.body?.status == 200) {
        setLoading(false);
        toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
        getUserList();
        setModal1(false);
      } else {
        setLoading(false);
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      setLoading(false);
      toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
    }
  };
  const udfnResetPIN = async (values) => {
    setLoading(true);
    const user = getLoggedInUser();
    var apiUrl = API_MASTER.MASTER_API.RESET_PIN;
    var uploadData = {
      user_id: Number(values.user_id),
      uid: Number(values.uid),
      new_pin: values.new_pin,
      loginfrom: 10
    }
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
    const response = await axios.post(process.env.REACT_APP_BASE_URL + apiUrl, uploadData, config);
    try {
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken();
        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser();
          } else {
            await udfnResetPIN(values);
          }
        }
      }
      if (response && response?.data?.body?.status == '200' || response && response?.data?.body?.status == 200) {
        setLoading(false);
        toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
        getUserList();
        setModal2(false);
      } else {
        setLoading(false);
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      setLoading(false);
      toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
    }
  };


  const onBlurPin1 = () => {
    two.current.focus();
  }

  const udfnPinValidation = (event) => {
    const input = event.target.value + event.key;
    if (input.length > 1) {
      event.preventDefault();
    }
    switch (event.target.id) {
      case "pin1":
        two.current.focus();
        break;
      case "pin2":
        three.current.focus();
        break;
      case "pin3":
        four.current.focus();
        break;
      case "pin4":
        one.current.focus();
        break;
      case "newpin1":
        newtwo.current.focus();
        break;
      case "newpin2":
        newthree.current.focus();
        break;
      case "newpin3":
        newfour.current.focus();
        break;
      case "newpin4":
        newone.current.focus();
        break;
      default:
        break;
    }
  };
  const onBlurnewpassword = () => {
    if (newpassword === '') {
      SetnewpasswordError(true)
      setresetpasswordError(constants.errorMessages.NEWPASSWORD_ERROR)
    } else if (!password_validation.test(newpassword)) {
      SetnewpasswordError(true)
      setresetpasswordError(constants.errorMessages.PASSWORD_VALIDATIONERROR)
    } else {
      SetnewpasswordError(false)
      setresetpasswordError('')
    }
  }
  const udfnClear = () => {
    setuserdetails();
    enableButton()
    validation.resetForm();
    setIsEdit(false);
    setselectedlevel(null);
    inputRef.current.focus();
  }
  const onBlurnewPIN = () => {
    if (newPIN === '') {
      SetnewPINError(true)
      setresetPINError(constants.errorMessages.NEWPASSWORD_ERROR)
    } else if (!password_validation.test(newpassword)) {
      SetnewPINError(true)
      setresetPINError(constants.errorMessages.PASSWORD_VALIDATIONERROR)
    } else {
      SetnewPINError(false)
      setresetPINError('')
    }
  }
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      displayname: (userdetails && userdetails.displayname) || '',
      loginid: (userdetails && userdetails.loginid) || '',
      password: (userdetails && userdetails.password) || '',
      deviceid: (userdetails && userdetails.deviceid) || '',
      pin1: (userdetails && userdetails.pin1) || '',
      pin2: (userdetails && userdetails.pin2) || '',
      pin3: (userdetails && userdetails.pin3) || '',
      pin4: (userdetails && userdetails.pin4) || '',
      newpin1: (userdetails && userdetails.newpin1) || '',
      newpin2: (userdetails && userdetails.newpin2) || '',
      newpin3: (userdetails && userdetails.newpin3) || '',
      newpin4: (userdetails && userdetails.newpin4) || '',
      uid: (userdetails && userdetails.uid) || 0,
      selectedlevel: (userdetails && userdetails.selectedlevel) || null
    },
    validationSchema: Yup.object({
      displayname: Yup.string().required("Please enter name of the user."),
      selectedlevel: Yup.object().required("Please select access level")
    }),
    onSubmit: (values) => {
      setLoading(true);
      var pinnumber = '';
      if (values.pin1) { pinnumber = String(values.pin1); }
      if (values.pin2) { pinnumber = String(pinnumber) + String(values.pin2); }
      if (values.pin3) { pinnumber = String(pinnumber) + String(values.pin3); }
      if (values.pin4) { pinnumber = String(pinnumber) + String(values.pin4); }
      if (pinnumber == '') { pinnumber = '0' }
      var statusid = 1;
      if (!status) { statusid = 2; }
      const addUser = {
        user_id: Number(user.uid),
        uid: Number(values.uid),
        displayname: values.displayname,
        accesslevel: Number(selectedlevel.value),
        loginid: values.loginid,
        password: values.password,
        deviceid: values.deviceid,
        pin: Number(pinnumber),
        statusid: statusid,
        process_type: isEdit ? 'update' : 'save'
      };
      disableButton();
      if (addUser.accesslevel == 10 || addUser.accesslevel == 12) {
        if (addUser.loginid == "" || addUser.loginid == null || addUser.loginid == undefined) {
          toast.error("Please enter login ID !", { position: toast.POSITION.TOP_RIGHT });
          enableButton();
          return;
        }
        if ((addUser.password == "" || addUser.password == null || addUser.password == undefined) && isEdit == false) {
          toast.error("Please enter password !", { position: toast.POSITION.TOP_RIGHT });
          enableButton();
          return;
        }
      }
      if (addUser.accesslevel == 11 || addUser.accesslevel == 12) {
        if (addUser.deviceid == "" || addUser.deviceid == null || addUser.deviceid == undefined) {
          toast.error("Please enter device ID !", { position: toast.POSITION.TOP_RIGHT });
          enableButton();
          return;
        }
        if ((addUser.pin == "" || addUser.pin == null || addUser.pin == undefined) && isEdit == false) {
          toast.error("Please enter pin number !", { position: toast.POSITION.TOP_RIGHT });
          enableButton();
          return;
        }
        if (String(addUser.pin).length != 4 && isEdit == false) {
          toast.error("Please enter valid pin number !", { position: toast.POSITION.TOP_RIGHT });
          enableButton();
          return;
        }
      }
      udfnSaveUser(addUser);
    },
  });
  const disableButton = () => {
    setButtonDisabled(true);
  };
  const enableButton = () => {
    setButtonDisabled(false);
  };
  const handleselectlevel = (selectedOption) => {
    setselectedlevel(selectedOption);
    if (selectedOption.value == 10 || selectedOption.value == 12) {
      setcustomActiveTab("1")
    }
    if (selectedOption.value == 11 || selectedOption.value == 12) {
      setcustomActiveTab("2")
    }
  };
  useEffect(() => {
    setCanCreate(true)
  }, [])
  const columns = useMemo(
    () => [
      {
        header: 'Actions',
        id: 'Action',
        width: '8%',
        cell: (cellProps) => {
          return (
            <div className="d-flex gap-1">
              <div
                to="#"
                className="btn btn-sm btn-soft-info"
                onClick={() => {
                  const userdata = cellProps.row.original;
                  onEditClick(userdata);
                }}
              >
                <i className="mdi mdi-pencil-outline" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </div>
              {(cellProps.row.original.accessid == "12" || cellProps.row.original.accessid == "10") && (

                <div to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    onClickreset(userData);
                  }}
                >
                  <i className="mdi mdi-lock-reset" id="resetpwdtooltip" />
                  <UncontrolledTooltip placement="top" target="resetpwdtooltip">
                    Reset Password
                  </UncontrolledTooltip>
                </div>
              )}
              {(cellProps.row.original.accessid == "11" || cellProps.row.original.accessid == "12") && (
                <div
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    onClickPINreset(userData);
                  }}
                >
                  <i className="mdi mdi-lock-reset" id="resettooltip" />
                  <UncontrolledTooltip placement="top" target="resettooltip">
                    Reset PIN
                  </UncontrolledTooltip>
                </div>)}
              {(cellProps.row.original.default == "0") && (
                <div to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const userdata = cellProps.row.original; onClickDelete(userdata);
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </div>)}
            </div>
          );
        }
      },
      {
        header: 'S.No.',
        // accessorKey: 'sno',
        width: '8%',
        enableColumnFilter: false,
        className: 'pl-25',
        cell: ({ row, table }) =>
          <div className="text-center">{
            (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
          }
          </div>
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Name of the user ▼
          </span>
        ),
        accessorKey: 'displayname',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Access Level ▼
          </span>
        ),
        accessorKey: 'accesslevel',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Login ID ▼
          </span>
        ),
        accessorKey: 'loginid',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Device ID ▼
          </span>
        ),
        accessorKey: 'deviceid',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Status
          </span>
        ),
        accessorKey: 'statusid',
        cell: (cellProps) => {
          const u_stsid = cellProps.row.original.statusid;
          return (
            <div className="text-center">
              {u_stsid === "1" ? (
                <i className="mdi mdi-check-circle text-success me-2" style={{ fontSize: '25px' }} />
              ) : (
                <i className="dripicons dripicons-wrong text-danger me-2" style={{ fontSize: '20px' }} />
              )}
            </div>
          );
        },
        enableColumnFilter: false,
        enableSorting: true,
      },
      // {
      //   header: (
      //     <span style={{ cursor: 'pointer' }}>
      //       Created By ▼
      //     </span>
      //   ),
      //   accessorKey: 'created',
      //   enableColumnFilter: false,
      //   enableSorting: true,
      //   cell: (cellProps) => {
      //     return (
      //       <div className="created">
      //             {cellProps?.row?.original?.created}<br/>
      //             {cellProps?.row?.original?.createdon}
      //       </div>
      //     );
      //   }
      // },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Last Updated
          </span>
        ),
        accessorKey: 'updated',
        // enableColumnFilter: false,
        enableColumnFilter: false,
        enableSorting: true, cell: (cellProps) => {
          const updatedby = cellProps?.row?.original?.updated;
          return (
            <div className="created">
              {updatedby === "" || updatedby === "-" ? (
                <div>{cellProps?.row?.original?.created}<br />
                  {cellProps?.row?.original?.createdon}</div>
              ) : (
                <div>{cellProps?.row?.original?.updated}<br />
                  {cellProps?.row?.original?.updatedon}</div>
              )}


            </div>
          );
        }
      }
    ],
    []
  );
  const onClickreset = (userData) => {
    setIsEditing(true)
    SetnewpasswordError(false)
    setresetpasswordError('')
    setconformpassword(false)
    SetconformpasswordError('')
    setuserdetails(userData);
    setuserconfirmpassword('')
    setnewpassword('')
    togglePasswordReset(userData.uid);
  };
  const onClickPINreset = (userData) => {
    setnewpin1();
    setnewpin2();
    setnewpin3();
    setnewpin4();
    setIsEditing(true)
    SetnewPINError(false)
    setresetPINError('')
    setconformPIN(false)
    SetconformPINError('')
    setuserdetails(userData);
    setuserconfirmPIN('')
    setnewpassword('')
    togglePINReset(userData.uid);
  };
  const togglePasswordReset = (uid) => {
    setModal1(!modal1);
    SetnewpasswordError(false)
    setresetpasswordError('')
    setconformpassword(false)
    SetconformpasswordError('')
    //setBoardUid(uid);
  };
  const togglePINReset = (uid) => {
    setModal2(!modal2);
    SetnewPINError(false)
    setresetPINError('')
    setconformPIN(false)
    SetconformPINError('')
  };
  const onClickDelete = (userdata) => {
    setuserdetails(userdata);
    setDeleteModal(true);
  };
  const handleAddClick = () => {
    enableButton()
    validation.resetForm();
    setIsEdit(false);
    setuserdetails('');
    setselectedlevel();
    toggle();
  };
  const toggle = () => {
    setModal(prev => !prev);
  };
  useEffect(() => {
    if (modal && inputRef.current && inputFocus) {
      inputRef.current.focus();
      setInputFocus(false);
    }
  }, [modal, inputFocus]);

  useEffect(() => {
    if (modal) {
      setInputFocus(true);
    }
  }, [modal]);

  const onEditClick = (userdata) => {
    enableButton()
    validation.resetForm();
    toggle();
    setIsEdit(true);
    validation.setValues({
      uid: userdata.uid,
      displayname: userdata.displayname,
      password: userdata.password,
      loginid: userdata.loginid,
      deviceid: userdata.deviceid,
      pin1: userdata.pin1,
      pin2: userdata.pin2,
      pin3: userdata.pin3,
      pin4: userdata.pin4,
      selectedlevel: {
        label: userdata.accesslevel,
        value: userdata.accessid
      }
    });
    setselectedlevel({
      label: userdata.accesslevel,
      value: userdata.accessid
    })
    if (userdata.accesslevel == "10") { setcustomActiveTab("1"); }
    else { setcustomActiveTab("2"); }
    if (userdata.statusid == "1")
      setstatus(true)
    else setstatus(false)
    setLoading(false);
  }
  const handleDeleteOrder = () => {
    setDeleteTrigger(true);
    setLoading(true);
    const deleteUser = {
      user_id: Number(user.uid),
      uid: Number(userdetails.uid)
    };
    udfnDeleteUser(deleteUser);
    setDeleteModal(false);
  };
  useEffect(() => {
    document.getElementById('headerTitle').innerHTML = 'User Management';
    getUserList();
    getAccessLevelList();
  }, []);
  const getUserList = async () => {
    setLoading(true);
    const data = {
      user_id: Number(user.uid),
      loginfrom: 10
    };
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
    try {
      const response = await axios.post(process.env.REACT_APP_BASE_URL + API_MASTER.MASTER_API.USER_MASTER_LIST, data, config);
      if (response && response.statusFlag === 3) {
        toast.error(response.message, { autoClose: 2000 });
        localStorage.removeItem("authUser");
        window.location.reload();
        return;
      }
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken();
        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser();
          } else {
            await getUserList();
          }
        }
      }
      if (response?.data?.body?.userlist) {
        setuserlist(response?.data?.body?.userlist);
      } else {
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("User List API Error:", error);
    }
  }
  const getAccessLevelList = async () => {
    setLoading(true);
    const data = {
      user_id: Number(user.uid),
      loginfrom: 10
    };
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
    try {
      const response = await axios.post(process.env.REACT_APP_BASE_URL + API_MASTER.MASTER_API.ACCESS_LEVEL_LIST, data, config);
      if (response && response.statusFlag === 3) {
        toast.error(response.message, { autoClose: 2000 });
        localStorage.removeItem("authUser");
        window.location.reload();
        return;
      }
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken();
        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser();
          } else {
            await getAccessLevelList();
          }
        }
      }
      if (response?.data?.body?.accesslevelresult) {
        setlevellist(response?.data?.body?.accesslevelresult);
      } else {
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Access Level List API Error:", error);
    }
  }
  const handleResetPasswordClick = () => {
    setIsEditing(true)
    if (newpassword === '') {
      SetnewpasswordError(true)
      setresetpasswordError(constants.errorMessages.NEWPASSWORD_ERROR)
    } else if (!password_validation.test(newpassword)) {
      SetnewpasswordError(true)
      setresetpasswordError(constants.errorMessages.PASSWORD_VALIDATIONERROR)

    } else {
      SetnewpasswordError(false)
      setresetpasswordError('')
    }
    if (newpassword !== '' && password_validation.test(newpassword)) {
      //  setResetTrigger(true);
      setIsEditing(true)
    }
    var values = {
      uid: userdetails.uid,
      user_id: user.uid,
      new_pwd: newpassword
    }
    udfnResetPassword(values);
  };
  const handleResetPINClick = () => {
    setIsEditing(true)
    if (newpassword === '') {
      SetnewPINError(true)
      setresetpasswordError(constants.errorMessages.NEWPASSWORD_ERROR)
    } else if (!password_validation.test(newpassword)) {
      SetnewpasswordError(true)
      setresetpasswordError(constants.errorMessages.PASSWORD_VALIDATIONERROR)

    } else {
      SetnewpasswordError(false)
      setresetpasswordError('')
    }
    if (newpassword !== '' && password_validation.test(newpassword)) {
      //  setResetTrigger(true);
      setIsEditing(true)
    }
    var pinnumber = '';
    if (newpin1) { pinnumber = String(newpin1); }
    if (newpin2) { pinnumber = String(pinnumber) + String(newpin2); }
    if (newpin3) { pinnumber = String(pinnumber) + String(newpin3); }
    if (newpin4) { pinnumber = String(pinnumber) + String(newpin4); }
    var values = {
      uid: userdetails.uid,
      user_id: user.uid,
      new_pin: pinnumber
    }
    udfnResetPIN(values);
  };
  const validatePasswordComplexity = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/;
    return regex.test(password);
  };
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content" style={{ height: '100%' }}>
        <div className="container-fluid">
          {/* <Breadcrumbs title="Masters" breadcrumbItem="Exam Board" /> */}
          <Row>
            {isLoading ?
              <Spinners setLoading={setLoading} />
              :
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={userlist || []}
                      isGlobalFilter={true}
                      isPagination={true}
                      isAddButton={canCreate}
                      buttonName="Add"
                      SearchPlaceholder="Search..."
                      pagination="pagination"
                      handleUserClick={handleAddClick}
                      // isCustomPageSize={true}
                      buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"
                      tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                      theadClass="table-light"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    />
                  </CardBody>
                </Card>
              </Col>
            }
          </Row>
          <Modal isOpen={modal} toggle={toggle} backdrop="static">
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit User" : "Add User"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>Name of the user<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        name="displayname"
                        type="text"
                        placeholder="Name of the user"
                        validate={{ required: { value: true } }}
                        maxLength={constants.textboxLength.itemgroup_name}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        autoComplete="off"
                        value={validation.values.displayname || ""}
                        invalid={validation.touched.displayname && validation.errors.displayname}
                        innerRef={inputRef} />
                      {validation.touched.displayname && validation.errors.displayname ? (
                        <FormFeedback type="invalid">{validation.errors.displayname}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>Access Level<span style={{ color: 'red' }}>*</span></Label>
                      <Select
                        value={selectedlevel}
                        onChange={(selectedOption) => {
                          handleselectlevel(selectedOption);
                          validation.setFieldValue("selectedlevel", selectedOption)
                        }}
                        options={levellist.map((level) => ({
                          label: level.mst_displaytext,
                          value: level.mstid,
                        }))}
                        validate={{
                          required: { value: true },
                        }}
                        className={`select2-selection ${validation.touched.selectedlevel && validation.errors.selectedlevel ? 'is-invalid' : ''}`}
                        invalid={
                          validation.touched.selectedlevel && validation.errors.selectedlevel ? true : false
                        }
                      />
                      {validation.touched.selectedlevel && validation.errors.selectedlevel ? (
                        <FormFeedback type="invalid">{validation.errors.selectedlevel}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col className="col-12">
                    <Card>
                      <CardBody>
                        <Nav tabs className="nav-tabs-custom nav-justified">
                          {(selectedlevel?.value == 10 || selectedlevel?.value == 12) ?
                            <NavItem>
                              <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "1", })} onClick={() => { toggleCustom("1"); }}>
                                <span className="d-block d-sm-none">
                                  <i className="fas fa-home"></i>
                                </span>
                                <span className="d-none d-sm-block">Web application</span>
                              </NavLink>
                            </NavItem> : null}
                          {(selectedlevel?.value == 11 || selectedlevel?.value == 12) ?
                            <NavItem>
                              <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "2", })} onClick={() => { toggleCustom("2"); }}>
                                <span className="d-block d-sm-none">
                                  <i className="far fa-user"></i>
                                </span>
                                <span className="d-none d-sm-block">Mobile application</span>
                              </NavLink>
                            </NavItem> : null}
                        </Nav>

                        <TabContent
                          activeTab={customActiveTab}
                          className="p-3 text-muted">
                          {(selectedlevel?.value == 10 || selectedlevel?.value == 12) ?
                            <TabPane tabId="1">
                              <Row>
                                <Col className="col-6">
                                  <Label>Login ID<span style={{ color: 'red' }}>*</span></Label>
                                </Col>
                                <Col className="col-6">
                                  <Input
                                    name="loginid"
                                    type="text"
                                    placeholder="Login ID"
                                    validate={{ required: { value: true } }}
                                    maxLength={constants.textboxLength.loginid}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.loginid || ""}
                                    autoComplete="off"
                                  // invalid={validation.touched.itemname && validation.errors.itemname}
                                  // innerRef={inputRef}
                                  />
                                  {validation.touched.itemname && validation.errors.itemname ? (
                                    <FormFeedback type="invalid">{validation.errors.itemname}</FormFeedback>
                                  ) : null}
                                </Col>
                                <Col className="col-6">
                                  {!isEdit && (
                                    <div className="mt-3">
                                      <Label>Password<span style={{ color: 'red' }}>*</span></Label>
                                    </div>)}
                                </Col>
                                <Col className="col-6">
                                  {!isEdit && (
                                    <div className="input-group auth-pass-inputgroup mt-3">
                                      <Input
                                        name="password"
                                        maxLength={constants.textboxLength.password}
                                        value={validation.values.password || ""}
                                        //  value={validation.values.password || ""}
                                        type={passwordShow ? "text" : "password"}
                                        //  type={passwordShow ? "text" : "password"}
                                        placeholder="Enter Password"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        autoComplete="off"
                                      // invalid={
                                      //   validation.touched.password && validation.errors.password ? true : false
                                      // }
                                      />
                                      <button
                                        onClick={() => setPasswordShow(!passwordShow)}
                                        className="btn btn-light " type="button" id="password-addon">
                                        <i className="mdi mdi-eye-outline"></i>
                                      </button>
                                    </div>)}
                                </Col>
                              </Row>
                            </TabPane> : null}
                          {(selectedlevel?.value == 11 || selectedlevel?.value == 12) ?
                            <TabPane tabId="2">
                              <Row>
                                <Col className="col-4">
                                  <div className="mt-3">
                                    <Label>Device ID<span style={{ color: 'red' }}>*</span></Label>
                                  </div>
                                </Col> <Col className="col-8">
                                  <div className="mt-3">
                                    <Input
                                      name="deviceid"
                                      type="text"
                                      placeholder="Device ID"
                                      validate={{ required: { value: true } }}
                                      maxLength={constants.textboxLength.deviceid}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      autoComplete="off"
                                      value={validation.values.deviceid || ""}
                                    // invalid={validation.touched.itemname && validation.errors.itemname}
                                    // innerRef={inputRef}
                                    /></div>
                                </Col>
                                {/* <Col className="col-12">
                        <Col className="col-4">  */}
                                <Col className="col-4">  {!isEdit && (<div className="mt-3">
                                  <Label>PIN<span style={{ color: 'red' }}>*</span></Label></div>)}</Col>
                                {/* </Col> */}
                                <Col className="col-2">
                                  {!isEdit && (
                                    <div className="mt-3">
                                      <Input
                                        id="pin1"
                                        name="pin1"
                                        type="number"
                                        onKeyPress={(e) => udfnPinValidation(e)}
                                        value={validation.values.pin1 || ""}
                                        ref={one}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        autoComplete="off"
                                        maxLength={constants.textboxLength.pin}
                                      /></div>)}
                                </Col>   <Col className="col-2">
                                  {!isEdit && (
                                    <div className="mt-3">
                                      <Input
                                        id="pin2"
                                        onKeyPress={(e) => udfnPinValidation(e)}
                                        name="pin2"
                                        type="number"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        autoComplete="off"
                                        ref={two}
                                        //value={validation.values.pin2 || ""}
                                        maxLength={constants.textboxLength.pin} /></div>
                                  )}
                                </Col>
                                <Col className="col-2">
                                  {!isEdit && (
                                    <div className="mt-3">
                                      <Input
                                        id="pin3"
                                        onKeyPress={(e) => udfnPinValidation(e)}
                                        name="pin3"
                                        type="number"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        ref={three}
                                        autoComplete="off"
                                        //value={validation.values.pin3 || ""}
                                        maxLength={constants.textboxLength.pin} /></div>)}
                                </Col><Col className="col-2">
                                  {!isEdit && (
                                    <div className="mt-3">
                                      <Input
                                        id="pin4"
                                        onKeyPress={(e) => udfnPinValidation(e)}
                                        name="pin4"
                                        type="number"
                                        autoComplete="off"
                                        ref={four}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        // value={validation.values.pin4 || ""}
                                        maxLength={constants.textboxLength.pin} /> </div>)}
                                </Col>
                                {/* </Col> */}
                              </Row>
                            </TabPane> : null}
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>    {isEdit && (
                    <div className="mb-3">
                      <Label>Status</Label>
                      <div className="square-switch">
                        <input
                          type="checkbox"
                          id="statusToggle"
                          className="switch switch-info"
                          checked={status}
                          onClick={() => setstatus(!status)} />
                        <label htmlFor="statusToggle" data-on-label="Active" data-off-label="Inactive" />
                      </div>
                    </div>)}
                    <div className="mb-9 text-end">
                      <Button
                        color="success"
                        type="submit"
                        className="save-user"
                        onClick={() => setSubmitbtn('savebtn')}
                        disabled={isButtonDisabled}>
                        {!!isEdit ? "Update" : "Save"}
                      </Button>
                      {!isEdit && (
                        <Button
                          color="primary"
                          type="submit"
                          className="save-and-continue-user"
                          onClick={() => setSubmitbtn('savecontinue')}
                          style={{ marginLeft: '10px' }}
                          disabled={isButtonDisabled}>
                          Save & Continue
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
          <Modal isOpen={modal1} toggle={togglePasswordReset} backdrop="static">
            <ModalHeader toggle={togglePasswordReset} tag="h4">
              Reset Password
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleResetPasswordClick();
                }}>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>New Password</Label>
                      <div className="input-group auth-pass-inputgroup">

                        <Input
                          innerRef={passwordInputRef}
                          name="newPassword"
                          autoComplete="off"
                          // type="password"
                          type={newpasswordShow ? "text" : "password"}
                          placeholder="New Password"
                          value={newpassword} onBlur={onBlurnewpassword} onChange={(e) => setnewpassword(e.target.value)}
                        />
                        <button onClick={() => setNewPasswordShow(!newpasswordShow)} className="btn btn-light " type="button" id="password-addon">
                          <i className="mdi mdi-eye-outline"></i>
                        </button>
                      </div>
                      <span style={{ color: 'red', fontSize: '10px' }}> {newpasswordError === true ? resetpasswordError : ''} </span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button color="success" disabled={isButtonDisabled}
                        type="submit" className="reset-password">
                        Update
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
          <Modal isOpen={modal2} toggle={togglePINReset} backdrop="static">
            <ModalHeader toggle={togglePINReset} tag="h4">
              Reset PIN
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleResetPINClick();
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>New PIN</Label>
                      {/* <div className="input-group auth-pass-inputgroup"> */}
                      <Row>
                        <Col className="col-2">
                          <div className="mt-3">
                            <Input
                              id="newpin1"
                              name="pin1"
                              type="number"
                              onKeyPress={(e) => udfnPinValidation(e)}
                              value={newpin1}
                              ref={newone}
                              //onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              autoComplete="off"
                              maxLength={constants.textboxLength.pin}
                              onChange={(e) => setnewpin1(e.target.value)}
                            /></div>
                        </Col>   <Col className="col-2">
                          <div className="mt-3">
                            <Input
                             onKeyPress={(e) => udfnPinValidation(e)}
                              ref={newtwo}
                              name="newpin2"
                              type="number"
                              onChange={(e) => setnewpin2(e.target.value)}
                              //onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              autoComplete="off"
                              value={newpin2}
                              maxLength={constants.textboxLength.pin} /></div></Col>
                        <Col className="col-2">
                          <div className="mt-3">
                            <Input
                             onKeyPress={(e) => udfnPinValidation(e)}
                              ref={newthree}
                              name="newpin3"
                              type="number"
                              onChange={(e) => setnewpin3(e.target.value)}
                              //onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              autoComplete="off"
                              value={newpin3}
                              maxLength={constants.textboxLength.pin} /></div></Col><Col className="col-2">
                          <div className="mt-3">
                            <Input
                             onKeyPress={(e) => udfnPinValidation(e)}
                              ref={newfour}
                              name="newpin4"
                              type="number"
                              autoComplete="off"
                              onChange={(e) => setnewpin4(e.target.value)}
                              //onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={newpin4}
                              maxLength={constants.textboxLength.pin} /> </div>
                        </Col>
                        {/* <Input
                                                    //innerRef={passwordInputRef}
                                                    name="newpin"
                                                   // type={newPINShow ? "text" : "password"}
                                                   type="number"
                                                    placeholder="New PIN"
                                                    value={newPIN} 
                                                     autoComplete="off"
                                                    // onBlur={onBlurnewPIN}
                                                     //onChange={(e) => setnewPIN(e.target.value)}
                                                /> */}
                        {/* <Col className="col-2"> <button style={{marginTop:"16px"}} onClick={() => setNewPINShow(!newPINShow)} className="btn btn-light " type="button" id="password-addon">
                                                    <i className="mdi mdi-eye-outline"></i>
                                                </button></Col>    */}
                        {/* </div> */}
                        <span style={{ color: 'red', fontSize: '10px' }}> {newPINError === true ? resetPINError : ''} </span>

                      </Row>  </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button color="success" disabled={isButtonDisabled}
                        type="submit" className="reset-password">
                        Update
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </React.Fragment>
  );
}

export default User;