import React, { useEffect, useMemo, useState, useRef } from "react";
import Select from "react-select";
import { ToastContainer,toast } from "react-toastify";
import Spinners from "../../components/Common/Spinner";
import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper"
import * as constants from "../../constants/layout"
import {Button, Col, Row, InputGroup, Label, Card, CardBody, FormGroup, CardTitle, CardText,Badge,UncontrolledTooltip, Table } from "reactstrap";
import "../../components/Common/common.scss";
import { MaterialReactTable, useMaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { Box, Typography } from '@mui/material';
import * as API_MASTER from '../../../src/api/masterapi';
import * as API_REPORTS from '../../api/reportsapi';
import axios from 'axios';
import moment from 'moment';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

// const logoutUser = async()=>{
//   localStorage.removeItem('userDetails');
//   setTimeout(() => navigate('/'), 700);
// };

const InwardDateWiseReport = () => {
  document.title = "Reports | Inward Date-wise Report";
  const [isLoading, setLoading] = useState(false);
  const user = getLoggedInUser();
  const [Inwardlist,setInwardlist] =useState([]);
  const [partylist,setpartylist] =useState([]);
  const [itemlist,setitemlist] =useState([]);
  const ExcelJS = require('exceljs');
  const [selectedDates, setSelectedDates] = useState([
    new Date(),
    new Date()
]);
const [selectedPartyName, setselectedPartyName] = useState({
  label: "All",
  value: 0
});
const [selectedItemName, setselectedItemName] = useState({
  label: "All",
  value: 0
});
const flatpickrRef = React.useRef(null);
  const handleDateChange = (selectedDates) => {
    setSelectedDates(selectedDates);
    if (selectedDates[0] != null && selectedDates[0] != undefined && selectedDates[1] != null && selectedDates[0] != undefined) {
      const flatpickrInstance = flatpickrRef.current.flatpickr;
      if (flatpickrInstance) {
          flatpickrInstance.close();
      }
      // getPartyList();
  }
};
useEffect(() => {
  if (selectedDates[0] != null && selectedDates[0] != undefined && selectedDates[1] != null && selectedDates[0] != undefined) {
    getPartyList()
}
 
}, [selectedDates[1]]); 
const columns = [
  {
    accessorKey: 'voucherno',
    header: 'Voucher No.',
  },
  {
    accessorKey: 'voucherdate',
    header: 'Voucher Date.',
  },
  {
    accessorKey: 'partyname',
    header: 'Party Name',
  },
   {
    accessorKey: 'noofitems',
    header: 'No.of Items',
       cell: (cellProps) => {
        return (
          <div className="text-right">
                {cellProps?.row?.original?.noofitems}
          </div>
        );
      }
    }
];
const nestedColumns = [
  {
    accessorKey: 'productname',
    header: 'Item Name',
  }, {
    accessorKey: 'productgroupname',
    header: 'Item Group',
  },
  {
    accessorFn: (row) => 
      <div style={{textAlign: 'right'}}>
              {parseFloat(row.qty).toFixed(row.noofdecimal)}
        </div>,
    header: 'Quantity',
  },
  {
    accessorKey: 'unit',
    header: 'Unit',
  }
];
 
const exportToExcel = async () => {
  let varParty =selectedPartyName.label;
  let varItem =selectedItemName.label;
  //setIsLoading(true)
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('Data')
  worksheet.mergeCells('A1', 'E1')
  const topRow = worksheet.getCell('A1')
  // Add headers with styling
  topRow.value="Inward Date-wise Report ("+moment(selectedDates[0]).format("DD-MM-YYYY")+" - "+moment(selectedDates[1]).format("DD-MM-YYYY")+") ";
  topRow.alignment = { vertical: 'center', horizontal: 'center' }
topRow.font = { bold: true }
topRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'e5de00' } // Background color
} 

const secondRow = worksheet.getCell('A2')
secondRow.value="Party : ";
secondRow.alignment = { vertical: 'center', horizontal: 'right' }
secondRow.font = { bold: true }
secondRow.fill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'F9DBBA' } 
}
const secondRow2 = worksheet.getCell('B2')
secondRow2.value=varParty;
secondRow2.alignment = { vertical: 'center', horizontal: 'left' }
secondRow2.font = { bold: true }
secondRow2.fill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'F9DBBA' } 
}
const secondRow5 = worksheet.getCell('C2')
secondRow5.value="";
secondRow5.alignment = { vertical: 'center', horizontal: 'center' }
secondRow5.font = { bold: true }
secondRow5.fill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'F9DBBA' } 
}
const secondRow3 = worksheet.getCell('D2')
secondRow3.value="Item Name : ";
secondRow3.alignment = { vertical: 'center', horizontal: 'right' }
secondRow3.font = { bold: true }
secondRow3.fill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'F9DBBA' } 
}
const secondRow4 = worksheet.getCell('E2')
secondRow4.value=varItem;
secondRow4.alignment = { vertical: 'center', horizontal: 'left' }
secondRow4.font = { bold: true }
secondRow4.fill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'F9DBBA' } 
}
const headercell1 = worksheet.getCell('A3')
headercell1.value="S.No.";
headercell1.alignment = { vertical: 'center', horizontal: 'center' }
headercell1.font = { bold: true }
headercell1.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'c0d5e8' } // Background color
}
const headercell11 = worksheet.getCell('B3')
headercell11.value="Item Name";
headercell11.alignment = { vertical: 'center', horizontal: 'center' }
headercell11.font = { bold: true }
headercell11.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'c0d5e8' } // Background color
}
const headercell2 = worksheet.getCell('C3')
headercell2.value="Item Group";
headercell2.alignment = { vertical: 'center', horizontal: 'center' }
headercell2.font = { bold: true }
headercell2.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'c0d5e8' } // Background color
}
const headercell4 = worksheet.getCell('D3')
headercell4.value="Quantity";
headercell4.alignment = { vertical: 'center', horizontal: 'center' }
headercell4.font = { bold: true }
headercell4.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'c0d5e8' } // Background color
}
const headercell5 = worksheet.getCell('E3')
headercell5.value="Unit";
headercell5.alignment = { vertical: 'center', horizontal: 'center' }
headercell5.font = { bold: true }
headercell5.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'c0d5e8' } // Background color
}  
  // Add data
  Inwardlist.forEach((item, index) => {
    const headerRow1 =   worksheet.addRow([
        "",
          item.voucherno,
          item.voucherdate,
          item.partyname,
         ""
      ])
      headerRow1.font = { bold: true }
      headerRow1.eachCell({ includeEmpty: true }, (cell) => {
        cell.fill = {
           type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '8fcc45' }, // Background color
          color:{argb:'FFFFFF'}
        };
      });
      item.items.forEach((inneritem, newindex) => {
        worksheet.addRow([
          newindex + 1,
            inneritem.productname,
            inneritem.productgroupname,
            inneritem.qty,
            inneritem.unit
        ])
    })
  })
  // Save the workbook
  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `Inward Date-wise Report.xlsx`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  // setIsLoading(false)
  // setExcelButtonFlag(false)
}   
  useEffect(() => {
    document.getElementById('headerTitle').innerHTML = 'Inward Date-wise Report';
  }); 
function handleSelectPartyName(selectedPartyName) {
    setselectedPartyName(selectedPartyName); }
useEffect(()=> {
  getPartyList()
  getItemList()
  getInwardList()
},[])
function handleSelectItemName(selectedItemName) {
  setselectedItemName(selectedItemName); }

const getPartyList = async () => {
  setLoading(true);
  const data = { user_id: Number(user.uid),
    loginfrom:10, type: 2, fromdate: moment(selectedDates[0]).format("YYYY-MM-DD"),todate:moment(selectedDates[1]).format("YYYY-MM-DD") };
 const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_URL +API_MASTER.MASTER_API.PARTY_MASTER_LIST , data,config);
      if (response && response.statusFlag === 3) {
          toast.error(response.message, { autoClose: 2000 });
          localStorage.removeItem("authUser");
          window.location.reload();
          return;
      }
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();
          if (refresh) {
              if (Number(refresh) === constants.invalidRefreshToken) {
                  logoutUser();
              } else {
                  await getPartyList();
              }
          }
      }
      if (response?.data?.body?.partylist) {
        response?.data?.body?.partylist.unshift({
          partyname: "All",
          ptid: 0
        })
        setpartylist(response?.data?.body?.partylist);
      } else {
          toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
      setLoading(false);
  } catch (error) {
      setLoading(false);
      console.error("Party List API Error:", error);
  }
}
const getItemList = async () => {
  setLoading(true);
  const data = { user_id: Number(user.uid),
    loginfrom:10 };
 const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_URL +API_MASTER.MASTER_API.ITEM_MASTER_LIST , data,config);
      if (response && response.statusFlag === 3) {
          toast.error(response.message, { autoClose: 2000 });
          localStorage.removeItem("authUser");
          window.location.reload();
          return;
      }
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();
          if (refresh) {
              if (Number(refresh) === constants.invalidRefreshToken) {
                  logoutUser();
              } else {
                  await getItemList();
              }
          }
      }
      if (response?.data?.body?.itemlist) {
        response?.data?.body?.itemlist.unshift({
          itemname: "All",
          prid: 0
        })
        setitemlist(response?.data?.body?.itemlist);
      } else {
          toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
      setLoading(false);
  } catch (error) {
      setLoading(false);
      console.error("Party List API Error:", error);
  }
}

const getInwardList = async () => {
  setLoading(true);
  const data = { user_id: Number(user.uid),ptid:Number(selectedPartyName?.value),fromdate:moment(selectedDates[0]).format("YYYY-MM-DD"),todate:moment(selectedDates[1]).format("YYYY-MM-DD"),productid:Number(selectedItemName?.value),
    loginfrom:10};
 const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_URL +API_REPORTS.REPORTS_API.REPORTS_INWARD_DATEWISE , data,config);
      if (response && response.statusFlag === 3) {
          toast.error(response.message, { autoClose: 2000 });
          localStorage.removeItem("authUser");
          window.location.reload();
          return;
      }
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();
          if (refresh) {
              if (Number(refresh) === constants.invalidRefreshToken) {
                  logoutUser();
              } else {
                  await getInwardList();
              }
          }
      }
      if (response?.data?.body?.inwardlist) {
        setInwardlist(response?.data?.body?.inwardlist);
      } else {
          toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
      setLoading(false);
  } catch (error) {
      setLoading(false);
      console.error("Inward date-wise report API Error:", error);
  }
}
  return (
    <React.Fragment>
      <div className="page-content" style={{ height: '100%' }}>
        <div className="container-fluid">
          {/* <Breadcrumbs title="Masters" breadcrumbItem="Exam Board" /> */}
          <Row>
            {isLoading ?
              <Spinners setLoading={setLoading} />
              :
              <Col lg={12}>
                <Card>
                  <CardBody>
                  <Row>
            <Col lg={12}>
                    <Row className="g-3">
                      <Col lg={4} >                        
                      </Col>
                        <Row>
                             
                        <Col lg={3}>
                        <div className="mb-3">
                            <FormGroup className="mb-4">
                                <Label>Period</Label>
                                <InputGroup>
                                  <Flatpickr data-enable-time
                                  ref={flatpickrRef}
                                      value={selectedDates}
                                      className="form-control d-block"
                                      options={{
                                          mode: "range",
                                          dateFormat: "d-m-Y",
                                          onChange: handleDateChange // Attach onChange handler to update state
                                      }}
                                  /> <button className="btn btn-light" type="button" id="calendar-addon">
                                  <i className="bx bx-calendar-event"></i>
                              </button>
                              </InputGroup>
                                {/* <div>
                                    Selected Dates: {selectedDates && selectedDates.length > 0 ? selectedDates.map(date => date.toLocaleDateString()).join(' - ') : 'Select a date range'}
                                </div> */}
                            </FormGroup>
                        </div>
                    </Col>
                    <Col lg={3}>
                            <div className="mb-3">
                              <Label>Party Name</Label>
                              <Select
                                value={selectedPartyName}
                                onChange={(selectedOption) => {
                                  handleSelectPartyName(selectedOption);
                                }} 
                                options={partylist.map((option) => ({
                                  label: option.partyname,
                                  value: option.ptid,
                                }))}
                                className="select2-selection"
                                />
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="mb-3">
                              <Label>Item Name</Label>
                              <Select
                                value={selectedItemName}
                                onChange={(selectedOption) => {
                                  handleSelectItemName(selectedOption);
                                }} 
                                options={itemlist.map((option) => ({
                                  label: option.itemname,
                                  value: option.prid,
                                }))}
                                className="select2-selection"
                                />
                            </div>
                          </Col>
                          <Col lg={1} >
                              <div className="d-flex flex-wrap gap-2 justify-content-start">
                                <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-soft-info"
                                  onClick={() => getInwardList()}>
                                  Apply
                                </Button>
                              </div>
                            </Col>
                            <Col lg={2} >
                                <div className='d-flex flex-wrap gap-2 justify-content-start'>
                                <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-soft-info"
                                //   onClick={() => exportToExcel()} disabled={excelButtonFlag ? true : false}>
                                    onClick={() => exportToExcel()} >
                                <i className="fa fa-download" /> &nbsp; Export
                                </Button>
                                </div>
                            </Col>
                        </Row>
                    </Row>               
            </Col>
          </Row>
          <MaterialReactTable
        columns={columns}
        data={Inwardlist}
        enableSorting={true}
        enableColumnFilters={false}
        enableGlobalSearch={false}
        muiToolbar={() => null} 
        enableTopToolbar={false}
        enableBottomToolbar= {false}
        enableColumnFilterModes={false}
        enablePagination={false}
        enableColumnActions={false}
        enableToolbarInternalActions={false}
        renderDetailPanel={({ row }) => (
          <Box sx={{ pl: 30, pr: 30 }}>
            <MaterialReactTable
              columns={nestedColumns}
              data={row.original.items}
              enableSorting={false} // Disable sorting for nested table if needed
              enablePagination={false} // Disable pagination for nested table if needed
              enableColumnFilters={false}
              enableColumnFilterModes={false}
              enableTopToolbar={false}
              enableColumnActions={false} />
          </Box>
        )}
          // Custom toolbar to avoid default search and other controls
      renderTopToolbarCustomActions={() => (
        <Box sx={{ p: 1}}>
        </Box>
      )} />
                  </CardBody>
                </Card>
                </Col>                
            }
          </Row>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </React.Fragment>
  );
}

export default InwardDateWiseReport;
