const MASTER_API = {
    //login 
    LOGIN_LIST: "api/login/login",

    // itemgroup master 
    ITEMGROUP_MASTER_LIST: "api/masters/listItemGroup",
    //itemgroup master save 
    ITEMGROUP_MASTER_SAVE: "api/masters/saveItemGroup",
    //itemgroup master update 
    ITEMGROUP_MASTER_UPDATE: "api/masters/updateItemGroup",
    //itemgroup master delete 
    ITEMGROUP_MASTER_DELETE: "api/masters/deleteItemGroup",
    //itemgroup master load 
    ITEMGROUP_MASTER_LOAD: "api/masters/loadItemGroup",

    //unit 
    UNIT_MASTER_LIST: "api/masters/listunit",
    //unit save 
    UNIT_MASTER_SAVE: "api/masters/saveUnit",
    //unit update 
    UNIT_MASTER_UPDATE: "api/masters/updateUnit",
    //unit delete 
    UNIT_MASTER_DELETE: "api/masters/deleteUnit",
    //unit load 
    UNIT_MASTER_LOAD: "api/masters/loadUnit",

    // item master 
    ITEM_MASTER_LIST: "api/masters/listItem",
    //item master save 
    ITEM_MASTER_SAVE: "api/masters/saveItem",
    //item master update 
    ITEM_MASTER_UPDATE: "api/masters/updateItem",
    //item master delete 
    ITEM_MASTER_DELETE: "api/masters/deleteItem",
    //item master load 
    ITEM_MASTER_LOAD: "api/masters/loadItem",

    // party master 
    PARTY_MASTER_LIST: "api/masters/listParty",
    //party master save 
    PARTY_MASTER_SAVE: "api/masters/saveParty",
    //party master update 
    PARTY_MASTER_UPDATE: "api/masters/updateParty",
    //party master delete 
    PARTY_MASTER_DELETE: "api/masters/deleteParty",
    //party master load 
    PARTY_MASTER_LOAD: "api/masters/loadParty",

    // user master 
    USER_MASTER_LIST: "api/masters/listUser",
    //user master save 
    USER_MASTER_SAVE: "api/masters/saveUser",
    //user master update 
    USER_MASTER_UPDATE: "api/masters/updateUser",
    //user master delete 
    USER_MASTER_DELETE: "api/masters/deleteUser",
    //user master load 
    USER_MASTER_LOAD: "api/masters/loadUser",

    // Change Password
    CHANGE_PASSWORD: "api/masters/changePassword",
    // Reset Password
    RESET_PASSWORD: "api/masters/resetPassword",
    // Reset PIN
    RESET_PIN: "api/masters/resetPIN",

    //Price Change
    PRICE_CHANGE: "api/masters/updatePriceList",

    // Load master data
    DECIMAL_LIST: "api/masters/listDecimals",  
    PARTY_CATEGORY_LIST: "api/masters/listPartyCategory",  
    ACCESS_LEVEL_LIST: "api/masters/listAccessLevel",    
    
};

export { MASTER_API};