import React, { useEffect, useMemo, useState, useRef } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import TableContainer from '../../components/Common/TableContainer';
import { ToastContainer } from "react-toastify";
import Spinners from "../../components/Common/Spinner";
import { useFormik } from "formik";
import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper"
import * as constants from "../../constants/layout"
import { useSelector, useDispatch } from "react-redux";
//import { getHeader   } from "../../../components/VerticalLayout/Header"
import { Button, Col, Row, Form, Input, Label, Card, CardBody, FormGroup, Badge,Container,Collapse } from "reactstrap";
import "../../assets/scss/tabledesign.scss";
import "../../components/Common/common.scss";
import * as API from '../../../src/api/reportsapi';
import * as API_MASTER from '../../../src/api/masterapi';
import axios from 'axios';
const PriceList = () => {
document.title = "Reports | Price List";
const [isLoading, setLoading] = useState(false);
const user = getLoggedInUser();
const [pricelist,setpricelist] =useState([]);
const [lastupdated,setlastupdated] =useState([]);
const [itemgrouplist,setItemgroupList] =useState([]);
const ExcelJS = require('exceljs');
const [selectedItemGroup, setselectedItemGroup] = useState({
  label: "All",
  value: 0
});

useEffect(() => {
  document.getElementById('headerTitle').innerHTML = 'Price List';
});
 
function handleSelectItemGroup(selectedItemGroup) { setselectedItemGroup(selectedItemGroup); }

useEffect(()=>{
  getItemGroupList()
  getPriceList()
  }, [])
  const logoutUser = async()=>{
    localStorage.removeItem('userDetails');
    setTimeout(() => navigate('/'), 700);
  };
const getItemGroupList = async () => {
  setLoading(true);
  const data = { user_id: Number(user.uid),
    loginfrom:10 };
 const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
  try {
     const response = await axios.post(process.env.REACT_APP_BASE_URL +API_MASTER.MASTER_API.ITEMGROUP_MASTER_LIST , data,config);
      if (response && response.statusFlag === 3) {
          toast.error(response.message, { autoClose: 2000 });
          localStorage.removeItem("authUser");
          window.location.reload();
          return;
      }
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();
          if (refresh) {
              if (Number(refresh) === constants.invalidRefreshToken) {
                  logoutUser();
              } else {
                  await getItemGroupList();
              }
          }
      }
      if (response?.data?.body?.itemgrouplist) {
       response?.data?.body?.itemgrouplist.unshift({
          itemgroupname: "All",
          prgid: 0
        })
        setItemgroupList(response?.data?.body?.itemgrouplist);
      } else {
          toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
      setLoading(false);
  } catch (error) {
      // Handle error
      setLoading(false);
      console.error("Item Group List API Error:", error);
  }
};
const getPriceList = async () => {
  setLoading(true);
  const data = {
      user_id: Number(user.uid),
      prgid: Number(selectedItemGroup.value),
      loginfrom:10
  };
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_URL + API.REPORTS_API.REPORTS_PRICELIST_API , data, config);
      if (response && response.statusFlag === 3) {
          toast.error(response.message, { autoClose: 2000 });
          localStorage.removeItem("authUser");
          window.location.reload();
          return;
      }
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();
          if (refresh) {
              if (Number(refresh) === constants.invalidRefreshToken) {
                  logoutUser();
              } else {
                  await getPriceList();
              }
          }
      }
      if (response?.data?.body?.pricelist) {
        setpricelist(response.data.body.pricelist);
        var lastupdatedvalue = "-";
        var lastupdate = response.data.body.pricelist[0].updated;
        if(lastupdate =="-" || lastupdate == null || lastupdate == undefined){lastupdatedvalue = "-"}
        else{lastupdatedvalue = response.data.body.pricelist[0].updated + " @ "+response.data.body.pricelist[0].updatedon}
        setlastupdated(lastupdatedvalue);
      } else {
          toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
      setLoading(false);
  } catch (error) {
    setLoading(false);
      console.error("Price List API Error:", error);
  }
}
function getCommaSeparatedTwoDecimalsNumber(number) {
  const fixedNumber = Number.parseFloat(number).toFixed(2);
  return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

}

const columns = useMemo(
    () => [
      {
        header: 'S.No.',
        width: '8%',
        enableColumnFilter: false,
        className: 'pl-25',
        cell: ({ row, table }) =>
          <div className="text-center">{
            (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
          }
          </div> 
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Item Name ▼
          </span>
        ),
        accessorKey: 'itemname',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Item Group ▼
          </span>
        ),
        accessorKey: 'itemgroupname',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Current Price ▼
          </span>
        ),
        accessorKey: 'price',
        enableColumnFilter: false,
        enableSorting: true, 
        cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-end"><label>₹ </label> 
                  {getCommaSeparatedTwoDecimalsNumber(cellProps?.row?.original?.price)}
            </div>
          );
        }
      },      
      // {
      //   header: (
      //     <span style={{ cursor: 'pointer' }}>
      //     Last Updated
      //   </span>
      //   ),
      //   accessorKey: 'updated',
      //   enableColumnFilter: false,
      //   enableSorting: false,
      //   cell: (cellProps) => {
      //     return (
      //       <div className="created">
      //             {cellProps?.row?.original?.updated}<br/>
      //             {cellProps?.row?.original?.updatedon}
      //       </div>
      //     );
      //   }
      // }
    ],
    []
  ); 
const exportToExcel = async () => {
  //setIsLoading(true)
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('Data')
  // Add headers with styling
  const headerRow = worksheet.addRow([
      'S.No.',
      'Item Name',
      'Item Group',
      'Current Price',
      'Last Updated By'
  ])
  headerRow.font = { bold: true }
  headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'c0d5e8' } // Background color
  }
  // Add data
  pricelist.forEach((item, index) => {
    var updateddetails = '-';
    if(item.updated && item.updated != '-'){updateddetails = item.updated +" @ "+ item.updatedon;}
      worksheet.addRow([
          index + 1,
          item.itemname,
          item.itemgroupname,
          item.price,
          updateddetails
      ])
  })
  // Save the workbook
  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `Price List.xlsx`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  // setIsLoading(false)
  // setExcelButtonFlag(false)
}
  return (
    <React.Fragment>
      <div className="page-content" style={{ height: '100%' }}>
        <div className="container-fluid">
          {/* <Breadcrumbs title="Masters" breadcrumbItem="Exam Board" /> */}
          <Row>
            {isLoading ?
              <Spinners setLoading={setLoading} />
              :
              <Col xs="12">
                <Card>
                  {/* <CardBody className="cust-table-ht"> */}
                  <CardBody >
                  <Row className="g-3">
                      <Col lg={4} >                        
                      </Col>
                        <Row>
                          <Col lg={3}>
                            <div className="mb-3">
                              <Label>Item Group</Label>
                              <Select
                                value={selectedItemGroup}
                                onChange={(selectedOption) => {
                                  handleSelectItemGroup(selectedOption);
                                }}
                                options={itemgrouplist?.map((option) => ({
                                  label: option.itemgroupname,
                                  value: option.prgid,
                                }))}
                                className="select2-selection"
                              />
                            </div>
                          </Col>
                          <Col lg={1} >
                              <div className="d-flex flex-wrap gap-2 justify-content-start">
                                <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-soft-info"
                                  onClick={() => getPriceList()}>
                                  Apply
                                </Button>
                              </div>
                            </Col>
                            <Col lg={2} >
                                <div className='d-flex flex-wrap gap-2 justify-content-start'>
                                <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-soft-info"
                                //   onClick={() => exportToExcel()} disabled={excelButtonFlag ? true : false}>
                                    onClick={() => exportToExcel()} >
                                <i className="fa fa-download" /> &nbsp; Export
                                </Button>
                                </div>
                            </Col>
                            <Col lg={3} ></Col>
                            <Col lg={3} >
                                <div className='d-flex flex-wrap gap-2 justify-content-start'>
                                <Label>Last Updated :</Label><br/>
                                <Label style={{bold:true}}>{lastupdated}</Label>
                                </div>
                            </Col>
                        </Row>
                    </Row>
                    <TableContainer
                        columns={columns}
                        data={pricelist || []}
                     //   isGlobalFilter={true}
                       // isPagination={true}
                       // isAddButton={canCreate}
                        buttonName="Add"
                       // SearchPlaceholder="Search..."
                       // pagination="pagination"
                        //handleUserClick={handleAddClick}
                       // isCustomPageSize={true}
                        buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"
                        tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                        theadClass="table-light"
                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    />
                  </CardBody>
                </Card>
              </Col>
            }
          </Row>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </React.Fragment>
  );
}

export default PriceList;