import React, { useEffect, useMemo, useState, useRef } from "react";
import Select from "react-select";
import TableContainer from '../../components/Common/TableContainer';
import { ToastContainer,toast } from "react-toastify";
import Spinners from "../../components/Common/Spinner";
import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper"
import * as constants from "../../constants/layout"
//import { getHeader   } from "../../../components/VerticalLayout/Header"
import { Button, Col, Row, InputGroup, Label, Card, CardBody, FormGroup } from "reactstrap";
import "../../assets/scss/tabledesign.scss";
import "../../components/Common/common.scss";
import * as API_MASTER from '../../../src/api/masterapi';
import * as API_REPORTS from '../../api/reportsapi';
import axios from 'axios';
import moment from 'moment';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { responsiveFontSizes } from "@mui/material";

const logoutUser = async()=>{
  localStorage.removeItem('userDetails');
  setTimeout(() => navigate('/'), 700);
};
const OutwardPartyWiseReport = () => {
  document.title = "Reports | Outward Party-wise Report";
  const [isLoading, setLoading] = useState(false);
  const user = getLoggedInUser();
  const [outwardlist,setoutwardlist] =useState([]);
  const [partylist,setpartylist] =useState([]);
  const [itemlist,setitemlist] =useState([]);
  const[totalqty,settotalqty] =useState(0);
  const ExcelJS = require('exceljs');
  const [excelButtonFlag, setExcelButtonFlag] = useState(false);
  const [selectedDates, setSelectedDates] = useState([
    new Date(),
    new Date()
]);
const [selectedPartyName, setselectedPartyName] = useState({
  label: "Select",
  value: 0
});
const [selectedItemName, setselectedItemName] = useState({
  label: "All",
  value: 0
});
const formatter = new Intl.NumberFormat('en-IN', {maximumSignificantDigits: 3 })
const flatpickrRef = React.useRef(null);
  const handleDateChange = (selectedDates) => {
    setSelectedDates(selectedDates);
    if (selectedDates[0] != null && selectedDates[0] != undefined && selectedDates[1] != null && selectedDates[0] != undefined) {
      const flatpickrInstance = flatpickrRef.current.flatpickr;
      if (flatpickrInstance) {
          flatpickrInstance.close();
      }
  }
};

useEffect(() => {
  if (selectedDates[0] != null && selectedDates[0] != undefined && selectedDates[1] != null && selectedDates[0] != undefined) {
    getPartyList()
}
}, [selectedDates[1]]); 

  const exportToExcel = async () => {
    setLoading(true)
    let varParty =selectedPartyName.label;
    let varItem =selectedItemName.label;
    if(varParty =="Select"){varParty="All";}
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Data')
    worksheet.mergeCells('A1', 'E1')
    const topRow = worksheet.getCell('A1')
    topRow.value="Outward Date-wise Report ("+moment(selectedDates[0]).format("DD-MM-YYYY")+" - "+moment(selectedDates[1]).format("DD-MM-YYYY")+") ";
    topRow.alignment = { vertical: 'center', horizontal: 'center' }
    topRow.font = { bold: true }
    topRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'e5de00' } // Background color
    } 
    //worksheet.mergeCells('A2', 'G2')
    const secondRow = worksheet.getCell('A2')
    secondRow.value="Party : ";
    secondRow.alignment = { vertical: 'center', horizontal: 'center' }
    secondRow.font = { bold: true }
    secondRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'F9DBBA' } 
    }
    const secondRow2 = worksheet.getCell('B2')
    secondRow2.value=varParty;
    secondRow2.alignment = { vertical: 'center', horizontal: 'center' }
    secondRow2.font = { bold: true }
    secondRow2.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'F9DBBA' } 
    }
    const secondRow5 = worksheet.getCell('C2')
    secondRow5.value="";
    secondRow5.alignment = { vertical: 'center', horizontal: 'center' }
    secondRow5.font = { bold: true }
    secondRow5.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'F9DBBA' } 
    }
    const secondRow3 = worksheet.getCell('D2')
    secondRow3.value="Item Name : ";
    secondRow3.alignment = { vertical: 'center', horizontal: 'center' }
    secondRow3.font = { bold: true }
    secondRow3.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'F9DBBA' } 
    }
    const secondRow4 = worksheet.getCell('E2')
    secondRow4.value=varItem;
    secondRow4.alignment = { vertical: 'center', horizontal: 'center' }
    secondRow4.font = { bold: true }
    secondRow4.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'F9DBBA' } 
    }
    const headercell1 = worksheet.getCell('A3')
    headercell1.value="S.No.";
    headercell1.alignment = { vertical: 'center', horizontal: 'center' }
    headercell1.font = { bold: true }
    headercell1.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'c0d5e8' } // Background color
    }
    const headercell2 = worksheet.getCell('B3')
    headercell2.value="Item Name";
    headercell2.alignment = { vertical: 'center', horizontal: 'center' }
    headercell2.font = { bold: true }
    headercell2.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'c0d5e8' } // Background color
    }
    const headercell3 = worksheet.getCell('C3')
    headercell3.value="Item Group";
    headercell3.alignment = { vertical: 'center', horizontal: 'center' }
    headercell3.font = { bold: true }
    headercell3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'c0d5e8' } // Background color
    }
    const headercell4 = worksheet.getCell('D3')
    headercell4.value="Quantity";
    headercell4.alignment = { vertical: 'center', horizontal: 'center' }
    headercell4.font = { bold: true }
    headercell4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'c0d5e8' } // Background color
    }
    const headercell5 = worksheet.getCell('E3')
    headercell5.value="Total Value";
    headercell5.alignment = { vertical: 'center', horizontal: 'center' }
    headercell5.font = { bold: true }
    headercell5.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'c0d5e8' } // Background color
    }
      // Add data
      outwardlist.forEach((item, index) => {
          worksheet.addRow([
              index + 1,
              item.itemname,
              item.itemgroupname,
              item.qty,
              item.price
          ])
      })
      // Save the workbook
      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `Outward Party-wise Report.xlsx`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      setLoading(false)
      setExcelButtonFlag(false)
  }
useEffect(()=> {
  getPartyList()
  getItemList()
  getoutwardlist()
},[])


const getItemList = async () => {
  setLoading(true);
  const data = { user_id: Number(user.uid),
    loginfrom:10 };
 const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_URL +API_MASTER.MASTER_API.ITEM_MASTER_LIST , data,config);
      if (response && response.statusFlag === 3) {
          toast.error(response.message, { autoClose: 2000 });
          localStorage.removeItem("authUser");
          window.location.reload();
          return;
      }
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();
          if (refresh) {
              if (Number(refresh) === constants.invalidRefreshToken) {
                  logoutUser();
              } else {
                  await getItemList();
              }
          }
      }
      if (response?.data?.body?.itemlist) {
        response?.data?.body?.itemlist.unshift({
          itemname: "All",
          prid: 0
        })
        setitemlist(response?.data?.body?.itemlist);
      } else {
          toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
      setLoading(false);
  } catch (error) {
      setLoading(false);
      console.error("Party List API Error:", error);
  }
}
const getPartyList = async () => {
  setLoading(true);
  const data = { user_id: Number(user.uid),
    loginfrom:10, fromdate:moment(selectedDates[0]).format("YYYY-MM-DD"),todate:moment(selectedDates[1]).format("YYYY-MM-DD"), type: 1  };
 const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_URL +API_MASTER.MASTER_API.PARTY_MASTER_LIST , data,config);
      if (response && response.statusFlag === 3) {
          toast.error(response.message, { autoClose: 2000 });
          localStorage.removeItem("authUser");
          window.location.reload();
          return;
      }
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();
          if (refresh) {
              if (Number(refresh) === constants.invalidRefreshToken) {
                  logoutUser();
              } else {
                  await getPartyList();
              }
          }
      }
      if (response?.data?.body?.partylist) {
        response?.data?.body?.partylist.unshift({
          partyname: "Select",
          ptid: 0
        })
        setpartylist(response?.data?.body?.partylist);
      } else {
          toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
      setLoading(false);
  } catch (error) {
      setLoading(false);
      console.error("Party List API Error:", error);
  }
}
const getoutwardlist = async () => {
  // if (Number(selectedPartyName.value) != 0 || Number(selectedItemName.value) != 0)
  // {
    setLoading(true);
    const data = { 
      user_id: Number(user.uid),
      ptid:Number(selectedPartyName.value),
      prid:Number(selectedItemName.value),
      fromdate:moment(selectedDates[0]).format("YYYY-MM-DD"),
      todate:moment(selectedDates[1]).format("YYYY-MM-DD"),
      loginfrom:10
    };
   const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
    try {
      const response = await axios.post(process.env.REACT_APP_BASE_URL +API_REPORTS.REPORTS_API.REPORTS_OUTWARD_PARTYWISE , data,config);
      if (response && response.statusFlag === 3) {
            toast.error(response.message, { autoClose: 2000 });
            localStorage.removeItem("authUser");
            window.location.reload();
            return;
        }
        if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
            const refresh = await getRefreshToken();
            if (refresh) {
                if (Number(refresh) === constants.invalidRefreshToken) {
                    logoutUser();
                } else {
                    await getoutwardlist();
                }
            }
        }
        if (response?.data?.body?.outwardlist) {
          setoutwardlist(response?.data?.body?.outwardlist);
          settotalqty(response?.data?.body?.totalvalue);
        } else {
            toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
        }
        setLoading(false);
    } catch (error) {
        setLoading(false);
        console.error("Outward party-wise report API Error:", error);
    }
  // }
  // else
  // {
  //   toast.error("Choose Party or Item in Filter", { position: toast.POSITION.TOP_RIGHT });
  // }
}

function getCommaSeparatedTwoDecimalsNumber(number) {
  const fixedNumber = Number.parseFloat(number).toFixed(2);
  return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

}
  const columns = useMemo(
    () => [
      {
        header: 'S.No.',
        width: '8%',
        enableColumnFilter: false,
        className: 'pl-25',
        cell: ({ row, table }) =>
          <div className="text-center">{
            (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
          }
          </div> 
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Item Name ▼
          </span>
        ),
        accessorKey: 'itemname',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Item Group ▼
          </span>
        ),
        accessorKey: 'itemgroupname',
        enableColumnFilter: false,
        enableSorting: true,
      },{
        header: (
          <span style={{ cursor: 'pointer' }}>
            Quantity ▼
          </span>
        ),
        accessorKey: 'Quantity',
        enableColumnFilter: false,
        enableSorting: true, 
        cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-end">
                  {parseFloat(cellProps?.row?.original?.qty).toFixed(cellProps?.row?.original?.noofdecimal)}
            </div>
          );
        }
      },{
        header: (
          <span style={{ cursor: 'pointer' }}>
           
          </span>
        ),
        accessorKey: 'unit',
        enableColumnFilter: false,
        enableSorting: true, 
        cell: (cellProps) => {
          return (
            <div className="text-left">
                  {cellProps?.row?.original?.unit}
            </div>
          );
        }
      },{
        header: (
          <span style={{ cursor: 'pointer' }}>
            Total Value
          </span>
        ),
        accessorKey: 'price',
        enableColumnFilter: false,
        enableSorting: true, 
        cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-end">
                  <label>₹ </label>{' '}{getCommaSeparatedTwoDecimalsNumber(cellProps?.row?.original?.price)}
            </div>
          );
        }
      }
    ],
    []
  );  
  function handleSelectPartyName(selectedPartyName) {
    setselectedPartyName(selectedPartyName); }
  useEffect(() => {
    document.getElementById('headerTitle').innerHTML = 'Outward Party-wise Report';
  }, []);

  function handleSelectItemName(selectedItemName) {
    setselectedItemName(selectedItemName); }
  useEffect(()=> {
    document.getElementById('headerTitle').innerHTML = 'Outward Party-wise Report';
  },[])
  return (
    <React.Fragment>
      <div className="page-content" style={{ height: '100%' }}>
        <div className="container-fluid">
          {/* <Breadcrumbs title="Masters" breadcrumbItem="Exam Board" /> */}
          <Row>
            {isLoading ?
              <Spinners setLoading={setLoading} />
              :
              <Col xs="12">
                <Card>
                  {/* <CardBody className="cust-table-ht"> */}
                  <CardBody >
                  <Row className="g-3">
                      <Col lg={4} >                        
                      </Col>
                        <Row>                             
                        <Col lg={4}>
                        <div className="mb-3">
                            <FormGroup className="mb-4">
                                <Label>Period</Label>
                                <InputGroup>
                                  <Flatpickr data-enable-time
                                  ref={flatpickrRef}
                                      value={selectedDates}
                                      className="form-control d-block"
                                      options={{
                                          mode: "range",
                                          dateFormat: "d-m-Y",
                                          onChange: handleDateChange // Attach onChange handler to update state
                                      }}/>
                                      <button className="btn btn-light" type="button" id="calendar-addon">
                                  <i className="bx bx-calendar-event"></i>
                              </button>
                              </InputGroup>
                                {/* <div>
                                    Selected Dates: {selectedDates && selectedDates.length > 0 ? selectedDates.map(date => date.toLocaleDateString()).join(' - ') : 'Select a date range'}
                                </div> */}
                            </FormGroup>
                        </div>
                    </Col>
                          <Col lg={3}>
                            <div className="mb-3">
                              <Label>Party Name</Label>
                              <Select
                                value={selectedPartyName}
                                onChange={(selectedOption) => {
                                  handleSelectPartyName(selectedOption);
                                }} 
                                options={partylist.map((option) => ({
                                  label: option.partyname,
                                  value: option.ptid,
                                }))}
                                className="select2-selection"
                                />
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="mb-3">
                              <Label>Item Name</Label>
                              <Select
                                value={selectedItemName}
                                onChange={(selectedOption) => {
                                  handleSelectItemName(selectedOption);
                                }} 
                                options={itemlist.map((option) => ({
                                  label: option.itemname,
                                  value: option.prid,
                                }))}
                                className="select2-selection"
                                />
                            </div>
                          </Col>
                          <Col lg={1} >
                              <div className="d-flex flex-wrap gap-2 justify-content-start">
                                <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-soft-info"
                                  onClick={() => getoutwardlist()}
                                 // onClick={() => setFilterData({selectedPartyName: selectedItemGroup ? selectedItemGroup.value : 0})}
                                 >
                                  Apply
                                </Button>
                              </div>
                            </Col>
                            <Col lg={2} >
                                <div className='d-flex flex-wrap gap-2 justify-content-start'>
                                <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-soft-info"
                                   onClick={() => exportToExcel()} disabled={excelButtonFlag ? true : false}>
                                   <i className="fa fa-download" /> &nbsp; Export
                                </Button>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="mini-stats-wid btn-soft-info btn-secondary">
                                <CardBody>
                                <div className="d-flex align-items-center"><div className="avatar-xs me-3">
                                    <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-18">
                                      <i className={"bx bx-rupee"}></i></span></div><h5 className="font-size-14 mb-0" style={{bold:'true'}}>{formatter.format(parseFloat(totalqty).toFixed(2))}</h5></div>

                                    {/* <div className="d-flex">
                                    <div className="flex-grow-1">
                                        <p className="text-muted fw-medium">
                                        Total Value
                                        </p>
                                        <h4 className="mb-0">{parseFloat(totalqty).toFixed(2)}</h4>
                                    </div>
                                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                        <span className="avatar-title rounded-circle bg-primary">
                                        <i className={"bx bx-archive-in font-size-24"}></i>
                                        </span>
                                    </div>
                                    </div> */}
                                </CardBody>
                                </div>
                            </Col>
                        </Row>
                    </Row>
                    <TableContainer
                        columns={columns}
                        data={outwardlist || []}
                      //  isGlobalFilter={true}
                        // isPagination={true}
                       // isAddButton={canCreate}
                        buttonName="Add"
                       // SearchPlaceholder="Search..."
                        // pagination="pagination"
                        //handleUserClick={handleAddClick}
                       // isCustomPageSize={true}
                        buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"
                        tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                        theadClass="table-light"
                        // paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    />
                  </CardBody>
                </Card>
              </Col>
            }
          </Row>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </React.Fragment>
  );
}

export default OutwardPartyWiseReport;