import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            {/* <Col md={6}>{new Date().getFullYear()} © Energy Cashews.</Col> */}
            <Col md={6}> </Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
              <p className="mb-0">
              v1.0.1 © {new Date().getFullYear()}. Powered by
                       <a   onClick={() => openInNewTab('https://trio-s.com/')} href="#"> Trio-S</a>
                      </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
