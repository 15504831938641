// src/components/filter.
import React, { useMemo } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import TableContainer from '../../../components/Common/TableContainer';

const BoardList = () => {

    const columns = useMemo(
        () => [
            {
                header: 'Action',
                // accessorKey: 'action',
                // enableColumnFilter: false,
                // enableSorting: true,
            },
            {
                header: 'S.No',
                accessorKey: 'sno',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Exam Board Name',
                accessorKey: 'boardname',
                enableColumnFilter: false,
                enableSorting: true,
            },
         
        ],
        []
    );

    const data = [
        {
            sno: "1",
            boardname: "Regional Director",
        },
      
       
    ];

    //meta title
    document.title = "Masters | Boards";

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Masters" breadcrumbItem="Boards" />

                <TableContainer
                    columns={columns}
                    data={data || []}
                    isGlobalFilter={true}
                    isPagination={true}
                    SearchPlaceholder="27 records..."
                    pagination="pagination"
                    paginationWrapper='dataTables_paginate paging_simple_numbers'
                    tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                />
            </div>
        </div>
    );
}
BoardList.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};


export default BoardList;