import React, { useEffect, useMemo, useState, useRef } from "react";
import * as Yup from "yup";
import Select from "react-select";
import TableContainer from '../../../components/Common/TableContainer';
import { ToastContainer,toast } from "react-toastify";
import Spinners from "../../../components/Common/Spinner";
import { useFormik } from "formik";
import DeleteModal from "../../../components/Common/DeleteModal";
import { getLoggedInUser, getRefreshToken } from "../../../helpers/fakebackend_helper"
import * as constants from "../../../constants/layout"
import { Button, Col, Row, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Form, Input, FormFeedback, Label, Card, CardBody, FormGroup, Badge } from "reactstrap";
import "../../../assets/scss/tabledesign.scss";
import "../../../assets/scss/common.scss";
import * as API_MASTER from '../../../api/masterapi';
import axios from 'axios';
const PartyMaster = () => {
  document.title = "Masters | Party";
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const inputRef = useRef(null);
  const [party, setParty] = useState(null);
  const [submitbtn, setSubmitbtn] = useState('');
  const [deleteTrigger, setDeleteTrigger] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [canCreate, setCanCreate] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const user = getLoggedInUser();
  const [selectedcategory, setselectedcategory] = useState(null);
  const [partylist,setpartylist] =useState([]);
  const [partycategorylist,setpartycategorylist] =useState([]);
  const [status, setstatus] = useState(false);
  const [status1, setStatus1] = useState(false);
  const logoutUser = async()=>{
    localStorage.removeItem('userDetails');
    setTimeout(() => navigate('/'), 700);
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      selectedcategory: (party && party.selectedcategory) || '',
      partyname: (party && party.partyname) || null,
      contactperson: (party && party.contactperson) || null,
      contactno: (party && party.contactno) || null,
      address: (party && party.address) || null,
      ptid:(party && party.ptid) || 0,
      status : status,
    },
    validationSchema: Yup.object({
        selectedcategory: Yup.object().required("Please select category"),
        partyname: Yup.string().required("Please enter party name")
    }),
    onSubmit: (values) => {
      setLoading(true);
      const addParty = {
        user_id: Number(user.uid),
        ptid:Number(values.ptid),
        partyname: values.partyname,
        pt_type:Number(selectedcategory.value),
        pt_contactperson: values.contactperson || '',
        pt_contactno: values.contactno || '',
        pt_address:values.address || '',
        pt_stsid:Number(status),
        process_type: isEdit ? 'update' : 'save'
      };
       disableButton();
       udfnSaveParty(addParty);
    },
  });  
  const udfnClear = ()=>{
    
    setParty();
    enableButton()
    validation.resetForm();
    setIsEdit(false);
    setselectedcategory(null);
    inputRef.current.focus();
  }
  const toggleStatus = () => {
    setStatus1(!status1);
    // status_text = status == true ? 0 : 1;
    // validation.values.status = status_text
};
  const udfnSaveParty = async (values) => { 
		setLoading(true);
		const user = getLoggedInUser();
    var apiUrl='';
		var uploadData = {};
    var varstatus ="1";    
    if(status1 =="0"){varstatus=2;}
		if (isEdit === true) {
			uploadData = {
				user_id: values.user_id,
				ptid:  values.ptid,
				pt_name: values.partyname.trim(),
        pt_type:Number(values.pt_type),
				pt_contactperson: String(values.pt_contactperson).trim(),
				pt_contactno: String(values.pt_contactno).trim(),
        pt_stsid:Number(varstatus),
				pt_address: values.pt_address.trim(),
        loginfrom:10
			};
			apiUrl =  API_MASTER.MASTER_API.PARTY_MASTER_UPDATE;
		} else {
			uploadData = {
				user_id:values.user_id,
				ptid:0,
        pt_name: values.partyname.trim(),
        pt_type:Number(values.pt_type),
				pt_contactperson: String(values.pt_contactperson).trim(),
				pt_contactno: String(values.pt_contactno).trim(),
				pt_address: String(values.pt_address).trim(),
        loginfrom:10
			};
			apiUrl =  API_MASTER.MASTER_API.PARTY_MASTER_SAVE;
		}
		const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
    const response = await axios.post(process.env.REACT_APP_BASE_URL +apiUrl , uploadData,config);
    try {
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();
          if (refresh) {
              if (Number(refresh) === constants.invalidRefreshToken) {
                  logoutUser();
              } else {
                  await udfnSaveParty(values);
              }
          }
      }    
      if (response && response?.data?.body?.status == '200' || response && response?.data?.body?.status == 200) {
        if (isEdit === true) {
          setModal(false);   
        }
        else{          
          if (submitbtn !== 'savecontinue') {
            setModal(false);        
          }
        }
        enableButton();
        udfnClear();
        toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
        getPartyList();
      } else {
        enableButton();
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
		} catch (error) {
      enableButton();
			setLoading(false);
      toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
		}
	}; 
  const udfnDeleteParty = async (values) => { 
		setLoading(true);
		const user = getLoggedInUser();
    var apiUrl =  API_MASTER.MASTER_API.PARTY_MASTER_DELETE;
		var uploadData = {
      user_id:values.user_id,
      ptid:values.ptid,
      loginfrom:10
		}
		const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
    const response = await axios.post(process.env.REACT_APP_BASE_URL +apiUrl , uploadData,config);
    try {
      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
          const refresh = await getRefreshToken();
          if (refresh) {
              if (Number(refresh) === constants.invalidRefreshToken) {
                  logoutUser();
              } else {
                  await udfnDeleteParty(values);
              }
          }
      }    
      if (response && response?.data?.body?.status == '200' || response && response?.data?.body?.status == 200) {       
        setLoading(false);
        toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
        getPartyList();
      } else {
        setLoading(false);
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
		} catch (error) {
			setLoading(false);
      toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
		}
	}; 
  const disableButton = () => {
    setButtonDisabled(true);
  };
  const enableButton = () => {
    setButtonDisabled(false);
  };

useEffect(()=> {
  setCanCreate(true)
},[])
  const columns = useMemo(
    () => [
      {
        header: 'Actions',
        id:'Action',
        width: '8%',
        cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <div
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      const partydata = cellProps.row.original;
                      onEditClick(partydata);
                    }}
                  >
                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </div>
                  <div  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const partydata = cellProps.row.original; onClickDelete(partydata);
                  }}
                  >
                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </div>
            </div>
          );
        }
      },
      {
        header: 'S.No.',
        width: '8%',
        enableColumnFilter: false,
        className: 'pl-25',
        cell: ({ row, table }) =>
          (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1

      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Party Name ▼
          </span>
        ),
        accessorKey: 'partyname',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Party Category ▼
          </span>
        ),
        accessorKey: 'partycategory',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Contact Person ▼
          </span>
        ),
        accessorKey: 'contactperson',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <div>
                  {cellProps?.row?.original?.contactperson}<br/>
                  {cellProps?.row?.original?.contactno}
            </div>
          );
        }
      },
      // {
      //   header: (
      //     <span style={{ cursor: 'pointer' }}>
      //       Contact No. ▼
      //     </span>
      //   ),
      //   accessorKey: 'contactno',
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Status
          </span>
        ),
        accessorKey: 'statusid',
        cell: (cellProps) => {
            const u_stsid = cellProps.row.original.statusid;
            return (
                <div className="text-center">
                    {u_stsid === 1 ? (
                        <i className="mdi mdi-check-circle text-success me-2" style={{ fontSize: '25px' }} />
                    ) : (
                        <i className="dripicons dripicons-wrong text-danger me-2" style={{ fontSize: '20px' }} />
                    )}
                </div>
            );
        },
        enableColumnFilter: false,
        enableSorting: true,
      },
      // {
      //   header: (
      //     <span style={{ cursor: 'pointer' }}>
      //       Created By ▼
      //     </span>
      //   ),
      //   accessorKey: 'created',
      //   enableColumnFilter: false,
      //   enableSorting: true,
      //   cell: (cellProps) => {
      //     return (
      //       <div className="created">
      //             {cellProps?.row?.original?.created}<br/>
      //             {cellProps?.row?.original?.createdon}
      //       </div>
      //     );
      //   }
      // },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
          Last Updated
        </span>
        ),
        accessorKey: 'updated',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const updatedby = cellProps?.row?.original?.updated;
          return (
            <div className="created">
            {updatedby === "" || updatedby === "-" ? (
              <div>{cellProps?.row?.original?.created}<br/>
                 {cellProps?.row?.original?.createdon}</div>
            ) : (
                 <div>{cellProps?.row?.original?.updated}<br/>
                 {cellProps?.row?.original?.updatedon}</div>
               )}
               </div>
          );
        }
      }
    ],
    []
  );
  const onClickDelete = (partyData) => {
    setParty(partyData);
    setDeleteModal(true);
  };
  const handleAddClick = () => {
  
    enableButton()
    validation.resetForm();
    setIsEdit(false);
    setParty('');    
    setselectedcategory();
    toggle();
  };

  const toggle = () => {
    setModal(prev => !prev);
  };
  const handleSelectGroup = (selectedOption) => {
    setselectedcategory(selectedOption);
  };
  useEffect(() => {
    if (modal && inputRef.current && inputFocus) {
      inputRef.current.focus();
      setInputFocus(false);
    }
  }, [modal, inputFocus]);

  useEffect(() => {
    if (modal) {
      setInputFocus(true);
    }
  }, [modal]);

  const onEditClick = (partydata) => {
    enableButton()
    validation.resetForm();
   // setParty(partydata);
    toggle();
    setIsEdit(true);
    validation.setValues({ 
      partyname: partydata.partyname,
      ptid:partydata.ptid,
      contactperson:partydata.contactperson,
      contactno:partydata.contactno,
      address:partydata.address,
      selectedcategory: {
        label: partydata.partycategory,
        value: partydata.mstid
      }
    });
    setselectedcategory({
      label: partydata.partycategory,
      value: partydata.mstid
    })
    if (partydata.statusid == "1"){
      setStatus1(true)
    }
    else{
      setStatus1(false)
    } 
    setLoading(false);
  }
  const handleDeleteOrder = () => {
    setDeleteTrigger(true);
    setLoading(true);  
    const deleteParty = {
      user_id: Number(user.uid),
      ptid:Number(party.ptid)
    };
    udfnDeleteParty(deleteParty);
    setDeleteModal(false);
  };
  useEffect(() => {
    document.getElementById('headerTitle').innerHTML = 'Party';
    getPartyList();
    getPartyCategoryList();
  }, []);
  const getPartyList = async () => {
    setLoading(true);
    const data = { user_id: Number(user.uid),
      loginfrom:10, fromdate: '', todate: '', type: 0 };
   const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
    try {
      const response = await axios.post(process.env.REACT_APP_BASE_URL +API_MASTER.MASTER_API.PARTY_MASTER_LIST , data,config);
        if (response && response.statusFlag === 3) {
            toast.error(response.message, { autoClose: 2000 });
            localStorage.removeItem("authUser");
            window.location.reload();
            return;
        }
        if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
            const refresh = await getRefreshToken();
            if (refresh) {
                if (Number(refresh) === constants.invalidRefreshToken) {
                    logoutUser();
                } else {
                    await getPartyList();
                }
            }
        }
        if (response?.data?.body?.partylist) {
          setpartylist(response?.data?.body?.partylist);
        } else {
            toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
        }
        setLoading(false);
    } catch (error) {
        setLoading(false);
        console.error("Party List API Error:", error);
    }
  }
  const getPartyCategoryList = async () => {
    setLoading(true);
    const data = { user_id: Number(user.uid),
      loginfrom:10 };
   const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
    try {
      const response = await axios.post(process.env.REACT_APP_BASE_URL +API_MASTER.MASTER_API.PARTY_CATEGORY_LIST , data,config);
        if (response && response.statusFlag === 3) {
            toast.error(response.message, { autoClose: 2000 });
            localStorage.removeItem("authUser");
            window.location.reload();
            return;
        }
        if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
            const refresh = await getRefreshToken();
            if (refresh) {
                if (Number(refresh) === constants.invalidRefreshToken) {
                    logoutUser();
                } else {
                    await getPartyCategoryList();
                }
            }
        }
        if (response?.data?.body?.partycategoryresult) {
          setpartycategorylist(response?.data?.body?.partycategoryresult);
        } else {
            toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
        }
        setLoading(false);
    } catch (error) {
        setLoading(false);
        console.error("Party Category List API Error:", error);
    }
  }
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content" style={{ height: '100%' }}>
        <div className="container-fluid">
          {/* <Breadcrumbs title="Masters" breadcrumbItem="Exam Board" /> */}
          <Row>
            {isLoading ?
              <Spinners setLoading={setLoading} />
              :
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={partylist || []}
                      isGlobalFilter={true}
                      isPagination={true}
                      isAddButton={canCreate}
                      buttonName="Add"
                      SearchPlaceholder="Search..."
                      pagination="pagination"
                      handleUserClick={handleAddClick}
                     // isCustomPageSize={true}
                      buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"
                      tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                      theadClass="table-light"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    />
                  </CardBody>
                </Card>
              </Col>
            }
          </Row>
          <Modal isOpen={modal} toggle={toggle} backdrop="static">
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Party" : "Add Party"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                  <div className="mb-3">
                      <Label>Party Name<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        name="partyname"
                        type="text"
                        placeholder="Party Name"
                        autoComplete="off"
                        validate={{ required: { value: true } }}
                        maxLength={constants.textboxLength.party_name}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.partyname || ""}
                        invalid={validation.touched.partyname && validation.errors.partyname}
                        innerRef={inputRef}/>
                      {validation.touched.partyname && validation.errors.partyname ? (
                        <FormFeedback type="invalid">{validation.errors.partyname}</FormFeedback>
                      ) : null}
                    </div>
                  <div className="mb-3">
                      <Label>Party Category<span style={{ color: 'red' }}>*</span></Label>
                      <Select
                        value={selectedcategory}
                        onChange={(selectedOption) => {
                          handleSelectGroup(selectedOption);
                          validation.setFieldValue("selectedcategory", selectedOption)
                        }}
                        options={partycategorylist.map((category) => ({
                          label: category.mst_displaytext,
                          value: category.mstid,
                        }))}
                        validate={{
                          required: { value: true },
                        }}
                        className={`select2-selection ${validation.touched.selectedcategory && validation.errors.selectedcategory ? 'is-invalid' : ''}`}
                        invalid={
                          validation.touched.selectedcategory && validation.errors.selectedcategory ? true : false
                        }
                      />
                      {validation.touched.selectedcategory && validation.errors.selectedcategory ? (
                        <FormFeedback type="invalid">{validation.errors.selectedcategory}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Contact Person</Label>
                      <Input
                        name="contactperson"
                        type="text"
                        placeholder="Contact Person"
                        validate={{ required: { value: true } }}
                        maxLength={constants.textboxLength.contact_person}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.contactperson || ""}
                            autoComplete="off"
                        invalid={validation.touched.contactperson && validation.errors.contactperson}/>
                      {validation.touched.contactperson && validation.errors.contactperson ? (
                        <FormFeedback type="invalid">{validation.errors.contactperson}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Contact No.</Label>
                      <Input
                        name="contactno"
                        type="number"
                        placeholder="Contact Number"
                       // validate={{ required: { value: true } }}
                        maxLength={constants.textboxLength.contact_no}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.contactno || ""}
                            autoComplete="off"
                        invalid={validation.touched.contactno && validation.errors.contactno}/>
                      {validation.touched.contactno && validation.errors.contactno ? (
                        <FormFeedback type="invalid">{validation.errors.contactno}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Address</Label>
                      <Input
                        name="address"
                        type="textarea"
                        placeholder="Address"
                       // validate={{ required: { value: true } }}
                        maxLength={constants.textboxLength.address}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.address || ""}
                        invalid={validation.touched.address && validation.errors.address}/>
                      {validation.touched.address && validation.errors.address ? (
                        <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                      ) : null}
                    </div>
                    {isEdit && (   
                    <div className="mb-3">
                            <Label>Status</Label>
                            <div className="square-switch">
                                <input
                                    type="checkbox"
                                    id="statusToggle"
                                    className="switch switch-info"
                                    checked={status1}
                                    onChange={toggleStatus}
                                />
                                <label htmlFor="statusToggle" data-on-label="Active" data-off-label="Inactive" />
                            </div>
                        </div>)}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        color="success"
                        type="submit"
                        className="save-user"
                        onClick={() => setSubmitbtn('savebtn')}
                        disabled={isButtonDisabled}

                      >
                        {!!isEdit ? "Update" : "Save"}
                      </Button>
                      {!isEdit && (
                        <Button
                          color="primary"
                          type="submit"
                          className="save-and-continue-user"
                          onClick={() => setSubmitbtn('savecontinue')}
                          style={{ marginLeft: '10px' }}
                          disabled={isButtonDisabled}>
                          Save & Continue
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </React.Fragment>
  );
}

export default PartyMaster;