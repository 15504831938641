import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Col, Container, Form, Row, Input, Label, FormFeedback } from "reactstrap";
import { useNavigate } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch ,} from "react-redux";
// import images
import toplogo from "../../assets/images/menulogo.png";
import logolight from "../../assets/images/trios-logo.jpg";
import CarouselPage from "./CarouselPage";
import withRouter from "components/Common/withRouter";
import * as constants from "../../constants/layout"
import * as API_MASTER from '../../../src/api/masterapi'
import axios from 'axios'
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Login = props => {
  const [passwordShow, setPasswordShow] = useState(false);
  document.title = "Login  | Energy Cashews";
  useEffect(() => {
    localStorage.removeItem("authUser")
  },[])
const navigate = useNavigate()
const validation = useFormik({
  // enableReinitialize : use this  flag when initial values needs to be changed
  enableReinitialize: true,

  initialValues: {
    // username: ""||'',
    // password: "Admin@123"|| '',
  },
  validationSchema: Yup.object({
    username: Yup.string().required("Please Enter Your UserName"),
    password: Yup.string().required("Please Enter Your Password"),
  }),
  onSubmit: (values) => {
    const req_data = {
      "username": values?.username,
      "password": values?.password,
      "loginfrom":10
    }
    login(req_data)
  }
});
const login = async (req) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_URL + API_MASTER.MASTER_API.LOGIN_LIST, req)
    if (response && response?.data?.body?.status === 200) {
      localStorage.setItem("authUser", JSON.stringify(response?.data?.body?.UserInfo));
      setTimeout(() => navigate('/outward-date-wise-report'), 700);
    } else if (response && response?.data?.body?.status === 401) {
      toast.error(response?.data?.body?.message, { autoClose: 2000 });
    } else {
      toast.error(response?.data?.body?.message, { autoClose: 2000 });
    }
  } catch (err) {
    console.error('err', err)
  }
}
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };
  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4" style={{backgroundColor:'#FFFFFF'}}>
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-2">
                      {/* <Link to="/dashboard" className="d-block card-logo"  style={{ alignItems: "center", display: "flex", flexDirection: 'column' }}> */}
                      <div className="mt-4 text-center" style={{ alignItems: "center", display: "flex", flexDirection: 'column' }}>
                     <img
                          src={toplogo}
                          alt=""
                          height="135"
                          className="logo-dark-element"
                        /></div>
                      {/* </Link> */}
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary text-center" >Welcome!</h5>
                      </div>

                      <div className="mt-4">
                        <Form className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Login ID</Label>
                            <Input
                              name="username"
                              className="form-control"
                              placeholder="Enter Login ID"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.username || ""}
                              invalid={
                                validation.touched.username && validation.errors.username ? true : false
                              }
                            />
                            {validation.touched.username && validation.errors.username ? (
                              <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            {/* <div className="float-end"> */}
                              {/* <Link to="/auth-recoverpw-2" className="text-muted">Forgot password?</Link> */}
                            {/* </div> */}
                            <Label className="form-label">Password</Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="password"
                                maxLength={constants.textboxLength.u_password}
                                value={validation.values.password || ""}
                                type={passwordShow ? "text" : "password"}
                                placeholder="Enter Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.password && validation.errors.password ? true : false
                                }
                              />
                              <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                                <i className="mdi mdi-eye-outline"></i>
                                </button>
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                            </div>
                            </div>

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block "
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>

                        </Form>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center" style={{ alignItems: "center", display: "flex", flexDirection: 'column' }}>
                    <img
                          src={logolight}
                          alt=""
                          height="45"
                          width="85"
                          className="logo-dark-element"
                        />
                      <p className="mb-0">
                      v1.0.1  © {new Date().getFullYear()}. Powered by
                       <a   onClick={() => openInNewTab('https://trio-s.com/')} href="#"> Trio-S</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </React.Fragment>
  );
};
export default withRouter(Login);
Login.propTypes = {
  history: PropTypes.object,
};
